.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  &.full-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.419);
    z-index: 9999;
  }

  &.in-container {
    width: 100%;
    height: 90% !important;

    .loader {
      width: 60%;
      height: 60%;
    }
  }
  &.background {
    background-color: rgba(58, 58, 58, 0.253);
  }
  .loader {
    width: 65%;
    height: 65%;
  }
}
.no-scroll {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
