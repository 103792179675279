@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.stepper-container {
  width: 100%;
  min-height: 30vh;

  .hide {
    @media (max-width: $tablet_width) {
      display: none;
      width: 0px;
    }
  }
}

.header {
  padding: 1rem 2rem;
  background-color: black;
  display: flex;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
  @media (max-width: $tablet_width) {
    padding: 0.5rem;
  }
  @media (max-width: $phone_width) {
    flex-direction: column;
  }
}

.stepper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1500px) {
    justify-content: flex-start;
    margin-top: 1rem;
  }
}

.step-container {
  display: flex;
  align-items: center;
  @media (max-width: $phone_width) {
    padding-top: 10px;
  }
}

.stepper .step {
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(20px, 5vw, 50px);
  height: clamp(20px, 10vh, 50px);
  margin: 0 5px 0px 5px;
  color: white;
  transition: 1s all ease-in-out;
  @media (max-width: $phone_width) {
    margin: 0 1px 0px 1px;
    width: 35px;
    height: 35px;
  }
}

.separator {
  width: 0;
  height: 2px;
  background-color: white;
  margin: 0 0.3rem;
  transition: width 1s;
  transition-timing-function: ease-in-out;
}

.stepper-container .step.active ~ .separator {
  width: 10vw;
  opacity: 1;
  @media (max-width: 1800px) {
    width: 2vw;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    width: 50px !important;
  }
  @media (max-width: $tablet_width) {
    width: 0px;
  }
  @media (max-width: $phone_width) {
    width: 10px;
  }
}

.active {
  background-color: white;
  color: black;
}

.inactive ~ .separator {
  @media (max-width: $tablet_width) {
    margin: 0rem;
  }
}
.inactive {
  background-color: rgba(135, 135, 135, 0.555);
  transition: 1s all ease-in-out;
}

.body-container {
  border: 1px solid $disabled_color_light_transparent;
}

.body-container .body {
  padding: 2rem 2rem 2rem 2rem;
  @media (max-width: $tablet_width) {
    padding: 1rem;
  }
}

.body-container .footer {
  padding: 1rem 2rem;
  background: #0000001a 0% 0% no-repeat padding-box;
}

.text {
  color: rgba(135, 135, 135, 0.555);
  transition: color 0.3s;
  // min-width: 115px;
  display: flex;
  @media (-webkit-device-pixel-ratio: 1.25) {
    min-width: 55px;
  }
  @media (max-width: $phone_width) {
    min-width: 40px;
  }
}
.checked {
  padding: 0rem 1rem;
  height: 45px;
  @media (max-width: $tablet_width) {
    padding: 0rem;
  }
  @media (max-width: $phone_width) {
    height: 33px;
    margin: 0rem;
  }
}

.logo {
  @media (max-width: $phone_width) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.672);
    padding-bottom: 10px;
  }
  img {
    width: 230px;
    @media (max-width: $tablet_width) {
      width: 180px;
      margin-top: 5px;
    }
    @media (max-width: $phone_width) {
      width: 180px;
      margin-top: 5px;
    }
  }
}
