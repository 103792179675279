@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.line {
  width: 1px;
  height: 35vh;
  position: fixed;
  z-index: 1;
  right: 2vw;
  bottom: 35vh;
  background-color: $secondary_color_semi_transparent;

  @media (max-width: $tablet_width) {
    right: 18px;
    // bottom: 350px;
  }
  @media (max-width: $phone_width) {
    display: none;
  }
}
