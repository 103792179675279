@import '../../../assets/styles/sizes';
.commingSoonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // background: linear-gradient(
  //   to right,
  //   #a1cdbdea,
  //   #95c5b3ea,
  //   #aaf1d7ea,
  //   #6cbea0d4,
  //   #85eec7c0,
  //   #529e82
  // );
}

.subContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subContainer {
  img {
    width: clamp(200px, 100%, 800px);
    @media (max-width: $phone_width) {
      width: 80%;
    }
  }
}

.background {
  background-image: url('./dpa-360.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
