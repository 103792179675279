@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/colors';

.overview-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .overview {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
  }
  .header-classname {
    // margin-top: 7rem;
    @media (max-width: $tablet_width) {
      margin-top: 2em;
    }
  }
  .students-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
    width: 100%;
    @media (-webkit-device-pixel-ratio: 1.5) {
      grid-template-columns: repeat(4, minmax(250px, 1fr));
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      grid-template-columns: repeat(4, minmax(250px, 1fr));
    }
    @media (-webkit-device-pixel-ratio: 1.1) {
      grid-template-columns: repeat(4, minmax(280px, 1fr));
    }
    @media (max-width: 1199px) and (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 991px) and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .desktop-courseCard {
    @media (max-width: $tablet_width) {
      display: none;
    }
  }
  .mobile-courseCard {
    display: none;
    @media (max-width: $tablet_width) {
      display: block;
    }
  }

  .next {
    left: -10px !important;
    opacity: 1;

    img {
      filter: $primary_color_svg;
    }
  }
  .prev {
    right: -10px !important;
    opacity: 1;
    img {
      filter: $primary_color_svg;
    }
  }
}

.overviewGap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.carousel {
  direction: ltr;
}

.dotsClassname {
  // right: 0;
  bottom: 10px !important;
}

.slider {
  height: auto !important;
}
