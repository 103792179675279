@import '../../../../assets/styles/sizes';

.about-the-academy {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  .toggle-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3vw;
    margin-top: 4rem;
    @media (max-width: $tablet_width) {
      flex-direction: column;
      margin-top: 1rem;
    }
  }
  .program {
    // width: 50%;
    text-align: center;
    @media (max-width: 1524px) {
      width: 70%;
    }
  }
}

.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
