@import '../../../../../assets/styles/sizes';

.Step-two {
  display: flex;
  flex-direction: column;
  // margin-top: 3rem;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @media (max-width: $tablet_width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .multi-image-label {
    margin-top: 2rem;
    // margin-bottom: -1.5rem;
  }
  .onPhone {
    @media (max-width: $phone_width) {
      margin-top: 1rem;
      display: flex;
      button {
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
