@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.major-card {
  background-color: rgba(255, 255, 255, 0.315);
  border-radius: 10px;
  padding: 2.5rem 2rem 0vw 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  z-index: 3;
  width: 330px;
  @media (-webkit-device-pixel-ratio: 1.5), (-webkit-device-pixel-ratio: 1.75) {
    padding: 2rem 2rem 0vw 2rem;
  }
  @media (max-width: $tablet_width) {
    padding: 2vh 1rem 0vw 1rem;
  }

  .major-title {
    height: 50px;
    margin-bottom: 1rem;
    @media (max-width: $phone_width) {
      height: 20px;
    }
  }
  @media (min-width: $tablet_width) {
    &:hover {
      background-color: $disabled_color_light_transparent;
      transition: 0.3s ease-in-out;

      .major-title {
        transform: translateY(-15px);
        transition: 0.3s ease-in-out;
      }
      .major-wrapper {
        transform: translateY(-15px);
        transition: 0.3s ease-in-out;
      }
    }
  }
  .title {
    // min-height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.major-wrapper {
  padding: 0.5rem 0rem 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.403);
  margin-top: 0.5rem;
  transition: 0.3s ease-in-out;
  @media (max-width: $phone_width) {
    // display: none;
    padding: 1vh;
  }
}
.svg-container {
  width: 100px;
  height: 50px;
  margin: auto;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.697);
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-top: 2vh;

  @media (-webkit-device-pixel-ratio: 1.5), (-webkit-device-pixel-ratio: 1.75) {
    padding: 0px 5px;
    width: 60px;
    height: 40px;
  }

  .image {
    transform: scale(0.9);
    width: clamp(40px, 2vw, 40px) !important;

    @media (-webkit-device-pixel-ratio: 1.5),
      (-webkit-device-pixel-ratio: 1.75) {
      transform: scale(0.6);
    }
  }

  .text-view {
    display: none;
    text-transform: capitalize;
  }
  @media (max-width: $tablet_width) {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    padding: 0em;
    img {
      display: none;
    }
    .text-view {
      display: block !important;
      // font-size: 18px;
      cursor: pointer;
    }
  }
  @media (max-width: $phone_width) {
    height: 30px;
    margin-top: 0vh;
    padding: 1px 5px;
  }
  &:hover {
    border-radius: 10px;
    .text-view {
      display: block;
      padding: 10px 15px;
    }
    img {
      display: none;
    }
  }
}
.emp-image {
  padding: 5px;
  // img {
  //   width: clamp(20px, 2vw, 20px) !important;
  // }

  img {
    transform: scale(0.9) !important;
    width: clamp(40px, 2vw, 40px) !important;
  }
}
