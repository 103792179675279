.text-container {
  width: 100%;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.036);
  }
}
.text-container-title {
  width: 100%;
  margin: 5px;
  cursor: pointer;

  padding: 10px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.036);
  }
}
.search-item {
  cursor: pointer;
  .item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .text {
    cursor: pointer;
    text-transform: uppercase;
  }
  .subtext {
    cursor: pointer;
  }
}

.search-item:last-child {
  border-bottom: none;
}
