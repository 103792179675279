@import '../../../../../../../assets/styles/sizes';

.events-container {
  width: 100%;
}

.grid {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  @media (max-width: $tablet_width) {
    flex-direction: column;
    gap: 1rem;
  }
}
.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
  text-transform: uppercase;
  @media (max-width: $tablet_width) {
    display: none !important;
  }
  .text {
    cursor: pointer;
  }
}
.view-all-button {
  display: none;
  margin-top: 1rem;
  padding: 1rem 0rem;
  text-transform: uppercase;
  @media (max-width: $tablet_width) {
    display: flex;
  }
}

.card {
  // height: 30vh;
  img {
    height: 100%;
    flex: 1;
    object-fit: cover;
  }
}
