.homePage {
  overflow: hidden;
  min-height: 100%;
  transition: 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.466);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
