@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.button {
  position: relative;
  background-color: transparent;
  border: 1px solid $primary_color;
  color: black;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 0.5rem 2rem;
  border-radius: 40px;
  margin-top: 10px;
  &:hover {
    background: rgb(0, 135, 85);
    background: linear-gradient(
      90deg,
      rgba(0, 135, 85, 1) 0%,
      rgba(0, 135, 85, 0.5130427170868348) 52%,
      rgba(0, 135, 85, 1) 100%
    );
    span {
      color: white;
    }
  }

  &:active {
    background: $primary_color;
  }
  @media (max-width: $phone_width) {
    width: 100% !important;
  }
}

.reverse {
  padding: 0.6rem 0.8rem;
  color: white !important;
  border: 1px solid $primary_color;
  background: linear-gradient(
    90deg,
    rgba(0, 135, 85, 1) 0%,
    rgba(0, 135, 85, 0.5130427170868348) 52%,
    rgba(0, 135, 85, 1) 100%
  );
  span {
    img {
      margin-right: 10px !important;
    }
  }
  &:hover {
    background: $primary_color;
    span {
      color: white;
    }
  }
}

.disabled {
  background-color: grey;
  &:hover {
    background: grey;
    span {
      color: black;
    }
  }
}
