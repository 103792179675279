.select-container {
  width: 100%;
  text-transform: capitalize;
}
.select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .indicator {
    padding-right: 10px;
    padding-left: 10px;
  }
}
