@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.image-upload {
  position: relative;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  .text {
    text-transform: capitalize;
  }
  .left-button {
    position: absolute;
    left: 15px;
    top: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    @media (max-width: $phone_width) {
      right: 0px;
      top: 35px;

      img {
        width: 25px;
      }
    }
  }
  .right-button {
    position: absolute;
    right: 15px;
    top: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    @media (max-width: $phone_width) {
      right: 0px;
      top: 35px;

      img {
        width: 25px;
      }
    }
  }
}
.custom-file-input {
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid #00000033;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 4px;
  // min-height: 130px;
  gap: 2rem;
  transition: 0.3s all ease-in-out;
  @media (max-width: $phone_width) {
    min-width: 40px;
    min-height: 80px;
  }
  .image {
    width: 60px;
    height: 60px;
    @media (max-width: $phone_width) {
      width: 40px;
      height: 40px;
    }
  }
  &:hover {
    background-color: $primary_color_Low_opacity;

    // .text {
    //   color: $primary_color;
    //   margin-bottom: 0.5rem;
    //   transition: 0.3s all ease-in-out;
    // }

    // .image {
    //   filter: $primary_color_svg;
    //   margin-top: 0.2rem;
    //   transition: 0.3s all ease-in-out;
    // }
  }
  .file {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
  }
}

.custom-file-input input[type='file'] {
  display: none;
}
