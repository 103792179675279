@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/sizes';

.gallery-info {
  position: relative;
  width: 90%;
  margin-top: 3rem;

  .album-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5em;
    overflow: scroll;
    height: 65vh;
    overflow-x: hidden;
    @media (-webkit-device-pixel-ratio: 1.5) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: $tablet_width) {
      grid-template-columns: repeat(3, 1fr);
      height: 57vh;
    }
    @media (max-width: $phone_width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.522);
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .text {
      text-transform: uppercase;
    }
  }
  .toTop {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.1rem;
    :hover {
      color: $primary_color;
      cursor: pointer;
      img {
        filter: $primary_color_svg;
      }
    }
  }
}
