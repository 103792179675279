@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.box-container {
  text-align: center;

  .text {
    font-size: 15px;
    margin-top: 1rem;
    color: $secondary_color;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 85px;
    background: #333131b3 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    @media (max-width: $phone_width) {
      width: 60px;
      height: 60px;
    }
    cursor: pointer;
    h1 {
      color: white;
      margin: 0px;
    }
  }
}

.active {
  @extend .box;
  background-color: #0087552f !important;
  color: #008755 !important;
  transition: 0.3s ease-in-out;
}
