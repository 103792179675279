@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.TestimonialCard {
  position: relative;
  display: flex;
  //   min-height: 700px;
  // height: 90%;
  width: 100%;
  border-radius: 20px;
  background-color: black;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  @media (max-width: 1500px) {
    flex-direction: column;
  }
}

.image {
  // width: clamp(100px, 45vw, 700px);
  max-width: 30%;
  height: 100%;
  // width: 100%;
  object-fit: cover;
  align-self: center;

  // border-top-left-radius: 20px;
  // border-bottom-left-radius: 20px;
  @media (max-width: 1300px) {
    width: 100%;
    height: 100%;
    // object-fit: contain;
    // border-top-left-radius: 20px;
    // border-top-left-radius: 20px;
    // border-top-left-radius: 0;
    // border-bottom-left-radius: 0;
  }
  @media (max-width: $phone_width) {
    min-width: 100%;
    height: 300px;
  }
}

.quote {
  padding: 4rem 0rem;
  background-color: #005844;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  @media (max-width: 1300px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media (max-width: 1300px) {
    padding: 5rem 3rem;
    @media (max-width: $phone_width) {
      padding: 2rem 2rem;
    }
  }
  @media (max-width: $phone_width) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.reversed {
  flex-direction: row-reverse;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  .image {
    width: 100%;
    align-self: center;
  }
}
.imageReverse {
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
  // border-bottom-left-radius: 0px;
  // border-top-left-radius: 0px;
  @media (max-width: 1300px) {
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
    // border-bottom-left-radius: 0px;
    // border-top-left-radius: 0px;
  }
}
.quoteReverse {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  @media (max-width: 1300px) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
.modalBody {
  overflow: hidden;
}
.dialogClassName {
  width: clamp(300px, 70%, 1000px);
  padding: 2rem 4rem;
  @media (max-width: 1300px) {
    padding: 1rem 2rem;
  }
}
.testimonialContainer {
  width: 100%;
  padding: 0;
}
.desc {
  // max-height: max-content !important;
  padding-bottom: 50px !important;
  text-align: justify;
  max-height: 60vh !important;
  overflow: auto;
}

.footerClass {
  position: relative !important;
  right: 0px !important;
}
