@font-face {
  font-family: '29LT Bukra Regular';
  src: url('./assets/fonts/TTF/29ltbukraregular.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: '29LT Bukra Regular';
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}
input {
  font-family: '29LT Bukra Regular';
}
html {
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 2px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #00754f;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

.lottie-container {
  position: absolute;
  top: 160px;
}

.no-scroll {
  overflow: hidden;
}
.rtl {
  direction: rtl;
  text-align: right;
}

button {
  outline: none !important;
}
.background-image-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

img {
  vertical-align: baseline !important;
}

.button {
  span {
    cursor: pointer;
  }
}

button {
  span {
    cursor: pointer;
  }
}

img {
  user-select: none;
}

html {
  font-size: 90%;
}

.dflex-container {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}
