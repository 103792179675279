@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/colors';

.Latest-container {
  position: relative;
  width: 100%;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: $tablet_width) {
    align-items: flex-start;
  }
  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: 0;
    animation: smoothZoom 50s infinite ease-in-out;
  }
  .title {
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $tablet_width) {
      margin-top: 10vh !important;
    }
  }
}
.latest {
  width: 90vw;
  box-sizing: border-box;
  height: 82vh;
  max-height: 82vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  @media (max-width: $phone_width) {
    width: 95vw;
  }
}
.container {
  display: flex;
  flex-direction: column;
}

.latest-view {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: $tablet_width) {
    flex-direction: column;
  }
  .text {
    text-align: end;

    @media (max-width: $tablet_width) {
      display: none !important;
    }
    span {
      color: $primary_color;
      border-bottom: 1px solid $primary_color;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    width: 80%;
    min-width: 500px;
    .contents {
      box-sizing: border-box;
      padding: 1vw;
      border-right: 1px solid $primary_color;
      min-height: 90%;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
  }
  .noBorders {
    border-right: 0px solid white !important;
  }
}

@keyframes smoothZoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
