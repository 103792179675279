@import '../../../../../../assets/styles/sizes';

.profile-container {
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  padding: 3rem 3rem 3rem 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 30%;
  height: 100%;
  // &.profile-container-rtl {
  //   // padding-left: 0px;
  //   // padding-right: 100px;
  //   // justify-content: flex-end;
  // }
  @media (max-width: 1400px) {
    padding: 4rem;
  }
  @media (max-width: $tablet_width) {
    padding-left: 0px;
    justify-content: center;
    padding: 3rem;
    padding-bottom: 5rem;
  }
  @media (max-width: $phone_width) {
    padding: 2rem;
    padding-bottom: 5rem;
  }
}

.student {
  width: 65%;
  @media (max-width: $tablet_width) {
    width: 100%;
    padding: 2rem;
    padding-bottom: 5rem;
  }
}
