@import '../../../../../../assets/styles/sizes';

// .margin-style {
//   @media (max-width: $tablet_width) {
//     padding-top: 30px;
//   }
//   @media (max-height: $device_with_less_height) and (min-width: 1280px) {
//     // padding-top: 40px;
//   }
// }
@import '../../../../../../assets/styles/sizes';
.slide {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  margin-top: 15vh;
  position: relative;
  text-align: center;
  @media (max-width: $tablet_width) {
    margin-top: 10vh;
  }
  @media (max-width: $phone_width) {
    margin-top: 15vh;
  }
  .content {
    width: 85%;
    margin: auto;
    margin-top: 100px;
    @media (max-width: $phone_width) {
      width: 85%;
    }
  }
  .grid-container {
    display: flex;
    align-items: center;
    min-height: 40dvh;
    width: 100%;
    // margin-top: 50px;
  }
  .grid {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: min-content;
    padding: 1rem;
    grid-auto-rows: 1fr;
    margin: auto;
  }

  .card {
    max-width: 400px;
    min-height: 155px;
    display: flex;
    flex-direction: column;
  }
  @media (max-height: 700px) {
    .card {
      // min-height: 160px;
      width: auto !important;

      flex: 1 1 calc(25% - 1rem);
    }
  }

  @media (max-width: 1200px) {
    .card {
      // height: 150px;
      flex: 1 1 calc(25% - 1rem);
    }
  }

  @media (max-width: 900px) {
    .card {
      // height: 160px;

      flex: 1 1 calc(33.33% - 1rem);
    }
  }
  @media (max-width: 600px) {
    .card {
      min-height: 110px;
      width: auto !important;

      // max-width: 130px;

      // flex: 1 1 calc(50% - 1rem);
    }
  }
}
// .M10vh {
//   margin-top: 10vh;
//   @media (max-width: $tablet_width) {
//     margin-top: 5vh !important;
//   }
// }

// .M15vh {
//   margin-top: 18vh;
//   @media (max-width: $tablet_width) {
//     margin-top: 2vh !important;
//   }
// }
