@import '../../../../../../../assets/styles/sizes';

.news-container {
  height: 100%;
  // @media (-webkit-device-pixel-ratio: 1.5) {
  // }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.5vw;
  max-height: 100%;
  width: 100%;
  @media (max-width: $tablet_width) {
    display: none !important;
  }
}

.horizontal-card-class-name {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  // max-width: 390px;
}

.vertical-cards-container {
  display: contents;
}

.vertical-card-class-name {
  grid-column: 2;
  // max-width: 800px;
}

.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 1vh 0rem;
  text-transform: uppercase;

  @media (max-width: $tablet_width) {
    display: none !important;
  }
  .text {
    cursor: pointer;
  }
}
.view-all-button {
  display: none;

  @media (max-width: $tablet_width) {
    margin-top: 1rem;
    padding: 1rem 0rem;
    text-transform: uppercase;
    display: flex;
  }
}

.gridMobile {
  display: none;

  @media (max-width: $tablet_width) {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
  }
}

.imageClass {
  height: 30px;
}
