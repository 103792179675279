@import '../../../assets/styles/sizes';

.slider-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  .title-container {
    position: absolute;
    z-index: 1;
    top: 20vh;
    left: 160px;

    @media (max-width: $phone_width) {
      left: 60px;
      top: 12vh;
    }
  }
  .title-container-rtl {
    right: 220px;
    text-align: right;
    @media (max-width: $phone_width) {
      right: 20px;
    }
  }
  .slider {
    overflow: hidden;
    height: 100%;
  }

  .slider-track {
    display: flex;
    flex-direction: column;
  }

  .slider-slide {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .controls {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    img {
      @media (-webkit-device-pixel-ratio: 1.5) {
        width: 70px;
        height: 70px;
      }
    }

    // @media (-webkit-device-pixel-ratio: 1.25) {
    //   right: -50px !important;

    //   img {
    //     width: 70px;
    //     height: 70px;
    //   }
    // }
    @media (max-width: $tablet_width) {
      bottom: 10vh;
      right: 50%;
      transform: translate(50%, 0);
      top: auto;
      transform: rotate(90deg);
    }
    @media (max-width: $phone_width) {
      bottom: 0px;
      // right: 50%;
      transform: translate(50%, 0);
      top: auto;
      transform: rotate(90deg);
    }
    @media screen and (min-width: 1000px) and (max-width: 1800px) {
      right: 0rem;
    }
  }
}
.avatar-rtl {
  @media (max-width: $phone_width) {
    right: 41% !important;
    margin-top: 20px !important;
  }
}
