@import '../../assets/styles/sizes';
.student-information {
  position: relative;
  height: 100vh;
  .general-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .text-container {
      display: flex;
      width: max-content;
      gap: 0.5rem;
      width: 100%;
      @media (max-width: $phone_width) {
        // flex-direction: column;
        // width: 10px;
      }
    }
    .text {
      display: flex;
      justify-content: space-between;
      min-width: 250px;
      width: max-content;
      text-align: justify;
      @media (max-width: $phone_width) {
        min-width: 0px;
        gap: 2px;
      }
    }
  }

  .student-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.447);
  }

  .profileImage {
    width: 100%;
    height: 100vh;
    object-position: 40% 38%;
    position: absolute;
    object-fit: cover;
    z-index: -1;
  }
  .navigation-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding: 0.8rem 9rem;
    background: #c9c9c9c6 0% 0% no-repeat padding-box;
    opacity: 1;
    @media (max-width: $tablet_width) {
      padding: 0.8rem 2rem;
    }
    @media (max-width: $phone_width) {
      padding: 0rem 1rem;
      img {
        width: 25px;
      }
    }
    // backdrop-filter: blur(20px);
    // -webkit-backdrop-filter: blur(20px);

    .buttonSvg {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.profileImageButton {
  // width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border: none;
  opacity: 1;
  cursor: pointer;
  .profilesvgs {
    width: 30px !important;
    height: 30px !important;
  }
}

.navigation-rtl {
  direction: rtl !important;
  background-color: red;
}

.dir {
  transform: rotate(180deg);
}
