@import '../../../../../assets/styles/sizes';

.Step-four {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .list-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .input-row {
    display: flex;
    gap: 1rem;
    @media (max-width: $tablet_width) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .text-area-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .input-row-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @media (max-width: $tablet_width) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .addMoreButton {
    @media (max-width: $tablet_width) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.m-top-1 {
  margin-top: 2rem;
}
.input-row-flex-3 {
  width: 33%;
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 568px) {
    width: 100%;
  }
}
