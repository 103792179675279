@import '../../../assets/styles/sizes';

.container {
  position: relative;
  width: 100vw;
  height: 85vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-height: 950px;
  min-height: 350px;

  @media (max-width: $tablet_width) {
    height: 70vh;
  }
  @media (max-width: $phone_width) {
    height: 100vh;
  }

  .backImage {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .degreeType-image {
    position: absolute;
    width: 100%;
    height: 100%;
    .left {
      position: absolute;
      right: 100px;
      width: 20%;
      bottom: 0px;
      z-index: -2;

      @media (max-width: $tablet_width) {
        display: none;
      }
    }
    .right {
      position: absolute;
      width: 20%;
      left: 100px;
      bottom: 0px;
      z-index: -2;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
  }

  .backImage {
    // width: 100vw;
    position: absolute;
    background-size: cover;
    // background-attachment: fixed;
    // object-fit: cover;
  }
}

.absolute {
  position: absolute !important;
  @media (max-width: $tablet_width) {
    left: 0%;
  }
}
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent
    linear-gradient(180deg, #0000006c 0%, #0000006c 79%, #00000084 100%) 0% 0%
    no-repeat padding-box;
  z-index: 0;
  height: 100%;
}
.heading-class {
  width: 50%;
  height: 85vh;
  display: flex;
  justify-content: center;
  direction: 'rtl';
  z-index: 99;
  max-height: 950px;
  min-height: 500px;
  margin: auto;
  margin-left: 10vw;
  @media (max-width: $tablet_width) {
    height: 70vh;
    width: 80%;
  }
  @media (max-width: $phone_width) {
    margin-left: 1rem;
    width: 80%;
    height: 80vh;
    margin: auto;
  }
}
.heading-class-rtl {
  margin-right: 10vw !important;
}

.hidden {
  display: none;
  z-index: -100;
}

// .autoBackground {
//   // background-size: auto !important;
//   // @media (max-width: $phone_width) {
//   //   background-size: auto !important;
//   // }
// }
.rtl-class {
  right: 10vw;
}
