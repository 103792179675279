.tables {
  // margin-top: 7rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.items {
  list-style: none !important;
}
.curiculum-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.buttonClass {
  text-transform: capitalize;
  &::before {
    border: 0px solid rgba(0, 0, 0, 0.298) !important;
    transition: width 0.3s ease-in-out;
  }
  &:hover {
    border: 1px solid black;
  }
}

.buttonImageWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(13%)
    hue-rotate(214deg) brightness(104%) contrast(105%);
}

.buttonImageBlack {
  filter: brightness(0) invert(1); /* Example for a black filter */
}
