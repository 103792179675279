@import '../../../../../assets/styles/sizes';

.parallax-section {
  width: 100%;
  height: 120dvh;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat !important;
  @media (max-width: $tablet_width) {
    width: 100%;
    height: 100vh;
  }
}

.foreground-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-attachment: fixed;
  // height: 90%;
  z-index: 0;
  @media screen and (max-width: $tablet_width) {
    height: 75%;
    object-fit: cover;
  }
  @media screen and (max-width: $phone_width) {
    height: 70%;
    object-fit: cover;
  }
}

.text-container {
  width: 60%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: $phone_width) {
    justify-content: start;
    align-items: start;
    margin-top: 0vh;
    width: 90%;
    display: flex;
    height: 100dvh;
    justify-content: center;
    // margin-top: 10vh;
  }

  .text {
    text-align: center;
    max-height: 0px;
    overflow: hidden;
  }
  &.animateText {
    .text {
      animation: heightGrow 15s ease forwards;
    }
  }
}
.side-modal {
  position: absolute;
  max-width: 35%;
  padding: 1rem 1.5rem;
  background: #ffffffad 0% 0% no-repeat padding-box;
  border-radius: 0px 120px 120px 0px;
  top: 3rem;
  left: -50%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: 2s all ease-in-out;
  @media (max-width: $tablet_width) {
    width: 100%;
    max-width: 100%;
    top: 120px;
    border-radius: 0px;
    min-height: 5vh;
    bottom: unset;
    padding: 1vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.button {
  background: #008755 0% 0% no-repeat padding-box;
  border-radius: 80px;
  outline: none;
  padding: 0.5rem 1rem;
  width: max-content;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $phone_width) {
    width: 120px !important;
    padding: 0.5rem 0.3rem;
    height: min-content;

    .text {
      font-size: 10px;
    }
  }
  span {
    cursor: pointer;
  }
}
.lotti-container {
  position: absolute;
  width: max-content;
  top: 16%;
  left: 0%;
  transition: 0.3s ease-in-out;
  @media (max-width: $tablet_width) {
    left: 15%;
    top: 20%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    display: none;
  }
  @media (max-width: $phone_width) {
    left: 20%;
    top: 10%;
    transform: translate(-50%, -50%);
    max-width: 70%;
  }
  .image {
    cursor: pointer;
    width: clamp(100px, 10vw, 200px);
  }
}
.opacity {
  opacity: 0.5;
}
.modalBody {
  max-width: 100%;
  .foreground {
    width: 100%;
    height: 100%;
  }
}

.video-container {
  width: 75vw;
  height: 80vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.video-wrapper {
  // width: 60vw;
  // height: 75vh;
  z-index: 1;

  @media (max-width: 1300px) {
    width: 70%;
    min-height: 10vh;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: $phone_width) {
    width: 90%;
    height: auto;
    margin-top: -100px;
  }
}
@keyframes heightGrow {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100%;
  }
}
.parallacSec {
  position: relative;
  height: 100%;
}

.side-modal-visible {
  left: 0;
  opacity: 1;
}
.img1988m4 {
  @media (max-width: $phone_width) {
    background-position: bottom center !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

.mobile_2010_background1 {
  @media (max-width: $phone_width) {
    background-position: bottom center !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

.img2018m1 {
  @media (max-width: $phone_width) {
    background-position: 60% 60% !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

.mobile_1978_front {
  @media (max-width: $phone_width) {
    background-position: -100% -100% !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

.img1978m1 {
  @media (max-width: $phone_width) {
    background-position: -0% -0% !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

.img1988m2 {
  @media (max-width: $phone_width) {
    background-position: center 180% !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

// .defaultInner {
//   left: unset;
//   right: 20px;
//   top: 300px;
// }

.reCenterImageOneTablet {
  // @media (max-width: $tablet_width) {
  background-position: 0% 0% !important;
  background-size: cover;
  background-repeat: no-repeat !important;
  // }
}

.mobile_2022_background3 {
  @media (max-width: $phone_width) {
    background-position: 0% 180% !important;
    background-size: cover;
    background-repeat: no-repeat !important;
  }
}

// .id11_1980_front_tablet {
//   @media (max-width: $phone_width) and (min-width: $tablet_width) {
//     background-position: 0% 150% !important;
//     background-size: cover;
//     background-repeat: no-repeat !important;
//     margin-top: 100px;
//   }
// }

.backdrop {
  position: absolute;
  background-color: rgba(30, 30, 30, 0.135);
  z-index: 0;
  width: 100%;
  height: 100%;
}
.lottiImage {
  height: 100%;
  width: 100%;
}
