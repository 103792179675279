.numbers-container {
  display: flex;
  gap: 3vw;
  .numbers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    margin-top: 2rem;
  }
}
