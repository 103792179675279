@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // @media (-webkit-device-pixel-ratio: 1.25) {
  //   gap: 1.4rem;
  // }
  .text {
    display: flex;

    span {
      width: 100%;
      text-align: center;
      opacity: 0.7;
    }
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 2rem;
    width: 100%;

    @media (max-width: $tablet_width) {
      grid-template-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    @media (max-width: $phone_width) {
      grid-template-columns: repeat(1, 1fr);
      gap: 0rem;
    }
  }

  .grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;
    @media (-webkit-device-pixel-ratio: 1.25) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $tablet_width) {
      grid-template-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    @media (max-width: $phone_width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;
  }
  .grid-7 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    grid-template-rows: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;

    @media (max-width: $tablet_width) {
      grid-template-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    @media (max-width: $phone_width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .dashes-separator {
    height: 1px;
    width: 100%;
    margin: 30px 0;

    background-image: repeating-linear-gradient(
      to right,
      $primary_color,
      $primary_color 12.5%,
      transparent 12.5%,
      transparent 25%
    );

    background-size: calc(100% / 5) 2px;
  }
  .heading-card {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00584429;
    border-radius: 10px;
    padding: 1.5rem;

    .svg {
      img {
        height: 5rem;
      }
    }

    .text-container {
      margin-top: 20px;
    }
  }
  .text {
    color: black;
    // opacity: 0.7;
  }
}
.card {
  width: 100px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.cardHover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover img {
    filter: $primary_color_svg;
    transition: 0.3s ease-in-out;
  }
  &:hover span {
    color: $primary_color;
    transition: 0.3s ease-in-out;
  }
  span {
    cursor: pointer;
  }
}

.images {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;

    // object-fit: cover;
  }
}
