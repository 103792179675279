@use 'sass:math';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/sizes';

// Mixin for common text styles
@mixin text-style($font-style-key) {
  font-family: map-get($fontStyles, $font-style-key, font-family);
  color: map-get($fontStyles, $font-style-key, color);
  font-weight: map-get($fontStyles, $font-style-key, font-weight);
  font-size: map-get($fontStyles, $font-style-key, font-size);
}
$base-font-size: 16px;
@function px-to-rem($size) {
  @return math.div($size, $base-font-size) * 1.1rem;
}
// Generate base sizes
@mixin responsive-font-size(
  $class,
  $phone-size,
  $tablet-size,
  $less-height: $tablet-size,
  $desktop-size
) {
  .#{$class} {
    font-size: calc(#{px-to-rem($desktop-size)} * var(--font-size-multiplier));
  }

  // @media (max-height: $device_with_less_height) and (min-width: 1300px) {
  //   .#{$class} {
  //     font-size: calc(#{px-to-rem($less-height)} * var(--font-size-multiplier));
  //   }
  // }

  @media (-webkit-min-device-pixel-ratio: 1.56) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.9
      );
    }
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.7
      );
    }
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.7
      );
    }
  }

  @media (-webkit-device-pixel-ratio: 1.25) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.8
      );
    }
  }
  @media (-webkit-device-pixel-ratio: 1.1) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.9
      );
    }
  }

  @media (-webkit-device-pixel-ratio: 1) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 1
      );
    }
  }

  // @media (max-width: 2000px) {
  //   .#{$class} {
  //     font-size: calc(
  //       #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.9
  //     );
  //   }
  // }

  @media (max-width: 1800px) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.8
      );
    }
  }

  @media (max-width: 1500px) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.7
      );
    }
  }

  @media (max-width: 1200px) {
    .#{$class} {
      font-size: calc(
        #{px-to-rem($desktop-size)} * var(--font-size-multiplier) * 0.8
      );
    }
  }
  // Media queries for tablet and phone widths
  @media (max-width: $tablet_width) {
    .#{$class} {
      font-size: calc(#{px-to-rem($tablet-size)} * var(--font-size-multiplier));
    }
  }
  @media (max-width: $phone_width) {
    .#{$class} {
      font-size: calc(#{px-to-rem($phone-size)} * var(--font-size-multiplier));
    }
  }
}

// Font Size: 14px
.text-regular-14 {
  @include text-style('bukra-regular-14px');
}
@include responsive-font-size('text-regular-14', 13px, 13px, 14px, 14px);

.text-bold-14-black {
  @include text-style('bukra-bold-14px-black');
}
@include responsive-font-size('text-bold-14-black', 15px, 13px, 14px, 14px);

// Font Size: 15px
.text-bold-italic-15 {
  @include text-style('bold-italic-15px');
}
@include responsive-font-size('text-bold-italic-15', 12px, 14px, 15px, 15px);

.text-bold-regular-15 {
  @include text-style('bold-regular-15px');
}
@include responsive-font-size('text-bold-regular-15', 12px, 14px, 15px, 15px);

// Font Size: 16px
.text-bold-italic-16 {
  @include text-style('bold-italic-16px');
}
@include responsive-font-size('text-bold-italic-16', 12px, 14px, 14px, 16px);

.text-bold-italic-16-black {
  @include text-style('bold-italic-16px-black');
}
@include responsive-font-size(
  'text-bold-italic-16-black',
  12px,
  14px,
  14px,
  16px
);

.text-bold-italic-16-green {
  @include text-style('bold-italic-16px-green');
}
@include responsive-font-size(
  'text-bold-italic-16-green',
  12px,
  15px,
  15px,
  16px
);

.text-regular-16 {
  @include text-style('regular-16px');
}
@include responsive-font-size('text-regular-16', 12px, 15px, 15px, 16px);

.text-regular-16-black {
  @include text-style('bold-regular-16px-black');
}
@include responsive-font-size('text-regular-16-black', 12px, 15px, 15px, 16px);

.text-regular-16-dark {
  @include text-style('bold-regular-16px-dark');
}
@include responsive-font-size('text-regular-16-dark', 12px, 15px, 15px, 16px);

// Font Size: 18px
.text-bold-italic-18 {
  @include text-style('bold-italic-18px');
}
@include responsive-font-size('text-bold-italic-18', 18px, 17px, 18px, 18px);

.text-bold-italic-18-black {
  @include text-style('bold-italic-18px-black');
}
@include responsive-font-size(
  'text-bold-italic-18-black',
  18px,
  17px,
  18px,
  18px
);

.text-regular-18 {
  @include text-style('bukra-regular-18px');
}
@include responsive-font-size('text-regular-18', 18px, 17px, 18px, 18px);

.text-regular-18-black {
  @include text-style('bukra-regular-18px-black');
}
@include responsive-font-size('text-regular-18-black', 12px, 17px, 18px, 18px);

.text-regular-18-green {
  @include text-style('bukra-regular-18px-green');
}
@include responsive-font-size('text-regular-18-green', 18px, 17px, 18px, 18px);

.text-bold-18 {
  @include text-style('bukra-bold-18px');
}
@include responsive-font-size('text-bold-18', 14px, 17px, 18px, 18px);
.text-bold-italic-20 {
  @include text-style('bold-italic-20px');
}
@include responsive-font-size('text-bold-italic-20', 13px, 18px, 20px, 20px);

.text-bold-italic-20-black {
  @include text-style('bold-italic-20px-black');
}
@include responsive-font-size(
  'text-bold-italic-20-black',
  14px,
  18px,
  20px,
  20px
);

.text-bold-20 {
  @include text-style('bukra-bold-20px');
}
@include responsive-font-size('text-bold-20', 15px, 18px, 20px, 20px);

.text-bold-20-black {
  @include text-style('bukra-bold-20px-black');
}
@include responsive-font-size('text-bold-20-black', 15px, 18px, 20px, 20px);

.text-bold-20-green {
  @include text-style('bukra-bold-20px-green');
}
@include responsive-font-size('text-bold-20-green', 15px, 18px, 20px, 20px);

.text-regular-20 {
  @include text-style('bukra-regular-20px');
}
@include responsive-font-size('text-regular-20', 13px, 18px, 20px, 20px);

.text-regular-20-black {
  @include text-style('bukra-regular-20px-black');
}
@include responsive-font-size('text-regular-20-black', 15px, 18px, 20px, 20px);

// Font Size: 22px
.text-bold-italic-22 {
  @include text-style('bold-italic-22px');
}
@include responsive-font-size('text-bold-italic-22', 17px, 20px, 22px, 22px);

.text-bold-22 {
  @include text-style('bold-regular-22px');
}
@include responsive-font-size('text-bold-22', 17px, 20px, 22px, 22px);

// Font Size: 25px
.text-bold-italic-25 {
  @include text-style('bukra-bold-italic-25px');
}
@include responsive-font-size('text-bold-italic-25', 19px, 23px, 25px, 25px);
.text-bold-regular-25 {
  @include text-style('bukra-bold-italic-25px');
}
@include responsive-font-size('text-bold-regular-25', 20px, 23px, 25px, 25px);

.text-bold-italic-25-black {
  @include text-style('bukra-bold-italic-25px-black');
}
@include responsive-font-size(
  'text-bold-italic-25-black',
  25px,
  23px,
  25px,
  25px
);

.text-bold-italic-25-green {
  @include text-style('bukra-bold-italic-25px-green');
}
@include responsive-font-size(
  'text-bold-italic-25-green',
  19px,
  23px,
  25px,
  25px
);

.text-bold-25-black {
  @include text-style('bukra-bold-25px-black');
}
@include responsive-font-size('text-bold-25-black', 19px, 23px, 25px, 25px);

// Font Size: 30px
.text-bold-italic-30 {
  @include text-style('bold-italic-30px');
}
@include responsive-font-size('text-bold-italic-30', 20px, 26px, 30px, 30px);

.text-bold-italic-30-black {
  @include text-style('bold-italic-30px-black');
}
@include responsive-font-size(
  'text-bold-italic-30-black',
  20px,
  26px,
  30px,
  30px
);

.text-bold-italic-30-green {
  @include text-style('bold-italic-30px-green');
}
@include responsive-font-size(
  'text-bold-italic-30-green',
  20px,
  26px,
  30px,
  30px
);
// Font Size: 30px
.text-bold-italic-30 {
  @include text-style('bold-italic-30px');
}
@include responsive-font-size('text-bold-italic-30', 18px, 24px, 30px, 30px);

.text-bold-italic-30-black {
  @include text-style('bold-italic-30px-black');
}
@include responsive-font-size(
  'text-bold-italic-30-black',
  18px,
  24px,
  30px,
  30px
);

.text-bold-italic-30-green {
  @include text-style('bold-italic-30px-green');
}
@include responsive-font-size(
  'text-bold-italic-30-green',
  18px,
  24px,
  30px,
  30px
);

// Font Size: 33px
.text-bold-italic-33 {
  @include text-style('bold-italic-33px');
}
@include responsive-font-size('text-bold-italic-33', 20px, 26px, 33px, 33px);

// Font Size: 40px
.text-bold-italic-40 {
  @include text-style('bold-italic-40px');
}
@include responsive-font-size('text-bold-italic-40', 24px, 32px, 20px, 40px);

.text-bold-italic-40-black {
  @include text-style('bold-italic-40px-black');
}
@include responsive-font-size(
  'text-bold-italic-40-black',
  24px,
  32px,
  25px,
  40px
);

.text-regular-bold-40 {
  @include text-style('bold-regular-40px');
}
@include responsive-font-size('text-regular-bold-40', 24px, 32px, 25px, 40px);

// Font Size: 50px
.text-bold-italic-50 {
  @include text-style('bold-italic-50px');
}
@include responsive-font-size('text-bold-italic-50', 23px, 38px, 50px, 50px);

// Font Size: 60px
.text-bold-italic-60 {
  @include text-style('bold-italic-60px');
}
@include responsive-font-size('text-bold-italic-60', 36px, 45px, 60px, 60px);

// Font Size: 70px
.text-bold-italic-70 {
  @include text-style('bold-italic-70px');
}
@include responsive-font-size('text-bold-italic-70', 42px, 52px, 70px, 70px);

// Font Size: 80px
.text-bold-italic-80 {
  @include text-style('bold-italic-80px');
}
@include responsive-font-size('text-bold-italic-80', 28px, 50px, 50px, 80px);

.text-bold-80 {
  @include text-style('bold-80px');
}
@include responsive-font-size('text-bold-80', 28px, 50px, 50px, 120px);
.text-container {
  position: relative;
  cursor: default;
  --line-height: 1.2em;
  img {
    vertical-align: middle !important;
  }
  // text-align: justify;
  &.has-line-break::after {
    content: '';
    display: block;
    position: absolute;
    background-color: $primary_color;
    border-radius: 12px;
    width: 1.5em;
    height: 0.23em;
    bottom: 0;
    left: 0px;
    bottom: calc(var(--line-height) * -0.1);
  }
  &.has-line-break-right::after {
    content: '';
    display: block;
    position: absolute;
    background-color: $primary_color;
    border-radius: 10px;
    width: 1.5em;
    height: 0.2em;
    bottom: 0;
    right: 0px;
    bottom: calc(var(--line-height) * -0.2);
  }

  .svg-icon-left {
    margin-right: 10px;
    max-width: 40px;
  }
  .svg-icon-right {
    margin-left: 10px;
    max-width: 40px;
  }
  .link {
    color: red;
    &:hover {
      color: $primary_color;
      cursor: pointer;
    }
  }
  .link-green {
    color: $primary_color;
    &:hover {
      color: red;
      cursor: pointer;
    }
  }
  p {
    margin: 0;
  }
}
.text-container-rtl::after {
  right: 0;
} // .text-container-rtl {
//   direction: rtl;
// }
