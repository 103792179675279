@import '../../../../assets/styles/sizes';

.degrees-contaner {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .desktop-dots {
    bottom: 10vh;
    @media (-webkit-device-pixel-ratio: 1.5) {
      bottom: 4vh;
    }
    @media (max-width: $tablet_width) {
      bottom: 10vh;
    }
    @media (max-width: $phone_width) {
      bottom: 10vh;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      bottom: 4vh;
    }
  }
}

.student1 {
  position: absolute;
  left: 10px;
  z-index: -1;
  @media (-webkit-device-pixel-ratio: 1.25) {
    height: 80%;
    left: 100px;
  }
  @media (max-width: $tablet_width) {
    display: none;
  }
}
.student2 {
  position: absolute;
  right: 10px;
  z-index: -1;
  @media (-webkit-device-pixel-ratio: 1.25) {
    height: 80%;
    right: 100px;
  }
  @media (max-width: $tablet_width) {
    display: none;
  }
}

.bachelorsImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -2;
  position: absolute;
}

.hue {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.301);
  z-index: 0;
}
