@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.profile-group {
  display: flex;
  align-items: center;

  .profile-image {
    width: 76px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: -20px;
    border: 2px solid $disabled_color_light_transparent;
    @media (max-width: $phone_width) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: $tablet_width) {
      width: 60px;
      height: 60px;
    }
  }
  .remaining-count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 76px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: -20px;
    border: 2px solid $disabled_color_light_transparent;
    background-color: grey;
    @media (max-width: $phone_width) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: $tablet_width) {
      width: 60px;
      height: 60px;
    }
  }
}
