.label {
  display: flex;
  .text {
    opacity: 0.7;
  }
  .required {
    position: relative;

    span {
      position: absolute;
      top: -8px;
      font-size: 18px;
      left: 4px;
      font-weight: 700;
      color: red;
    }
  }
  // .text-error {
  //   color: red;
  // }
}

.labelRtl {
  gap: 1rem;
  // justify-content: flex-end;
}

.secondary {
  opacity: 0.6;
}
