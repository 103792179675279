@import '../../../../../../assets//styles//colors';
@import '../../../../../../assets//styles/sizes';

.slide-one {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .content {
    position: relative;
    z-index: 1;
    width: clamp(400px, 40%, 500px);

    @media (max-width: $phone_width) {
      width: clamp(300px, 40%, 500px);
    }
  }
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.students-info {
  position: relative;
  // margin-top: 4rem;
  // height: 150px;
  padding: 10vh clamp(1rem, 3vw, 2rem) 10vh clamp(1rem, 3vw, 2rem);
  width: 100%;
  @media (max-width: $tablet_width) {
    padding: 2rem;
    padding-bottom: 5rem;
  }
  @media (max-width: $tablet_width) {
    padding: 2rem 0.5rem;
  }
  .left-quotes {
    position: absolute;
    top: -20px;
    left: -20px;
    height: 2rem;
    width: 2rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
      hue-rotate(146deg) brightness(106%) contrast(100%);

    @media (max-width: $tablet_width) {
      left: -20px;
      top: 30px;
    }

    @media (max-width: $phone_width) {
      top: 0px;
      left: -10px;
    }
  }
  .right-quotes {
    position: absolute;
    bottom: -10px;
    right: -20px;
    height: 2rem;
    width: 2rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
      hue-rotate(146deg) brightness(106%) contrast(100%);
    @media (max-width: $tablet_width) {
      right: -0px;
      bottom: 0;
    }
    @media (max-width: $phone_width) {
      right: 3px;
    }
  }
  // Right Border
  .right-border {
    content: '';
    position: absolute;
    width: 1px;
    background: rgba(255, 255, 255, 0.473);
    top: 0;
    right: 0;
    height: 50%;
  }

  // Left Border
  .left-border {
    content: '';
    position: absolute;
    width: 1px;
    background: rgba(255, 255, 255, 0.473);
    bottom: 0%;
    left: 0;
    height: 60%;
  }
  // Top Border
  &::before {
    content: '';
    position: absolute;
    height: 2px;
    background: rgba(255, 255, 255, 0.473);
    top: 0;
    right: 0;
    left: 10%;
    @media (max-width: $tablet_width) {
      left: 40%;
    }
    @media (max-width: $phone_width) {
      left: 20%;
    }
  }

  // Bottom Border
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: rgba(255, 255, 255, 0.473);
    bottom: 0;
    left: 0;
    right: 10%;
    @media (max-width: $tablet_width) {
      right: 40%;
    }
    @media (max-width: $phone_width) {
      right: 20%;
    }
  }
  .description {
    margin-top: 1rem;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  line-height: 1.5;
  .text {
    text-transform: capitalize;
  }
}
