@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/sizes';

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  max-height: 100px;
  bottom: 0px;
  z-index: 0;
  opacity: 1;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  z-index: 1;

  @media (-webkit-device-pixel-ratio: 1.25) {
    height: 9vh;
  }
  // @media (max-height: $device_with_less_height) {
  //   height: 5rem;
  // }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    width: 200px !important;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.192);
    padding: 1rem;
    text-transform: uppercase;
  }
  @media (max-width: $tablet_width) {
    display: none;
  }
}

.navigationsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  // width: 80%;
}

.navigationsContainer > * {
  flex: 1;
}

.disableResponsive {
  @media (max-width: $tablet_width) {
    display: flex !important;
    flex-direction: column;
    .navigationsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.container-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .link {
    width: 100%;
    padding: 0.4rem;
    color: $secondary_color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.5s ease-in-out;
    user-select: none;
    text-decoration: none;
    max-height: 30px;
    text-transform: uppercase;
  }

  .bordered {
    border: 1px solid $secondary_color;
    border-radius: 50px;
  }

  .text {
    position: relative;
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $primary_color;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 100%;
      bottom: -5px;
      height: 1px;
      background-color: $primary_color;
      transition:
        right 0.4s cubic-bezier(0.86, 0, 0.07, 1),
        background-color 0.5s ease-in-out;
    }
  }
}

.active {
  .text {
    color: $primary_color;

    &::after {
      right: 0;
    }
  }
}

.with-right-odd {
  border-left: 1px solid $secondary_color;
}
.with-left-border {
  border-right: none !important;
  border-left: 1px solid $secondary_color;
}
.with-right-border {
  border-right: 1px solid $secondary_color;
}

.sss {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  &:hover {
    color: $primary_color;
  }
}
