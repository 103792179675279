@import '../../../../../../assets/styles/sizes';
@import '../../../../../../assets/styles/colors';

.gallery {
  width: 100%;
  margin-top: 5rem;
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 1rem;
    max-width: 70%;
    @media (max-width: $tablet_width) {
      max-width: 100%;
    }
    @media (max-width: $phone_width) {
      max-width: 100%;
    }
  }
  .text {
    text-transform: uppercase;
  }
  .inputStyle {
    width: 65%;
    height: 40px;
    input {
      background-color: rgba(255, 255, 255, 0.51);
      border-color: white;
      color: white;
      height: 40px;

      &::placeholder {
        color: white;
      }
    }
  }
  .media-grid {
    width: 85%;
    // min-height: 300px;
    margin: auto;
    margin-top: 1rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 1rem;
    scrollbar-color: rgba(255, 255, 255, 0.735) rgba(255, 255, 255, 0.728);
    scrollbar-width: thin;

    // @media (-webkit-device-pixel-ratio: 1.5) {
    //   min-height: 210px;
    // }
    // @media (-webkit-device-pixel-ratio: 1.25) {
    //   min-height: 260px;
    // }
    @media (max-width: $tablet_width) {
      max-width: 100%;
    }
    @media (max-width: $phone_width) {
      width: 90%;
    }
    > * {
      flex: 1 0 auto;

      @media (min-width: 1200px) {
        max-width: calc(25% - 0.5rem);
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        max-width: calc(33.333% - 0.5rem);
      }
      @media (max-width: $tablet_width) and (min-width: 768px) {
        max-width: calc(50% - 0.5rem);
      }
      @media (max-width: $phone_width) {
        max-width: calc(100%);
      }
    }
  }
}
