@import '../../assets/styles/colors';

.error-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button {
    background-color: white;
    text-transform: uppercase;
  }
  .image {
    width: 100%;
  }

  .background {
    position: absolute;
    z-index: -1;
  }
  .text {
    text-transform: uppercase;
    color: $primary_color;
    text-align: center;
  }
  .error-action {
    display: flex;
    flex-direction: column;
  }
}
