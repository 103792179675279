@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/sizes';

.universal-map {
  background: transparent
    url('../../../../assets/images/home/universalMap/studentsBackground1.jpg')
    0% 0% no-repeat padding-box;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .flag-placeholder {
    width: 40px !important;
    height: 40px !important;
    @media (max-width: $phone_width) {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .container {
    position: relative;
    margin: auto;
    width: 90vw;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
    @media (-webkit-device-pixel-ratio: 1.75) {
      width: 70vw;
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 80vw;
    }
    @media (max-width: $tablet_width) {
      align-items: flex-start;
      margin-top: 14rem;
    }
    .map {
      position: relative;
      transition: 0.3s ease-in-out;
      .country-dot {
        position: absolute;
        cursor: pointer;

        &:hover .flag-circle {
          opacity: 1;
          transform: scale(1);
        }
        &.active {
          .flag-circle {
            opacity: 1;
            transform: scale(1);
          }
        }
        .flag-circle {
          position: absolute;
          top: 42px;
          left: 5px;
          width: 30px;
          height: 7px;
          border-radius: 50%;
          z-index: 0 !important;
          border: 1px solid $secondary_color_semi_transparent;
          background-color: $secondary_color_semi_transparent;
          opacity: 0;
          transition: 0.3s ease-in-out;
        }
        span {
          position: absolute;
          width: 32px;
          height: 32px;
          left: 4.2px;
          top: 2px;
          border-radius: 50%;
          @media (max-width: $phone_width) {
            width: 22px !important;
            height: 22px !important;
          }
        }
      }
      @media (-webkit-device-pixel-ratio: 1.75) {
        width: 75%;
      }
      @media (-webkit-device-pixel-ratio: 1.5) {
        width: 70%;
      }
      @media (-webkit-device-pixel-ratio: 1.25) {
        width: 70%;
      }
      @media (max-width: $tablet_width) {
        margin-top: -20px;
        img {
          width: 100%;
        }
      }
      @media (max-height: $device_with_less_height) and (min-width: 1280px) {
        img {
          width: 100%;
        }
      }
      @media (max-height: $tablet_with_less_height) {
        // margin-top: -50px;
        img {
          width: 100%;
        }
      }
    }
    .heading-cotainer {
      position: absolute;
      bottom: 10px;
      left: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: $tablet_width) {
        bottom: 100px;
      }
      @media (max-width: $phone_width) {
        bottom: 250px;
      }
      @media (max-height: 800px) and (min-width: 1000px) {
        bottom: 50px;
      }
    }

    .alumni {
      position: absolute;
      bottom: 10px;
      right: 0;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      @media (max-width: $tablet_width) {
        bottom: 10vh;
      }
      @media (max-width: $phone_width) {
        bottom: 150px;
        left: 0;
      }
      @media (max-height: 800px) and (min-width: 1000px) {
        bottom: 50px;
      }
    }
    .alumni-students {
      position: absolute;
      top: 15px;
      right: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
  }
}
