@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.students-info {
  position: relative;
  // margin-top: 4rem;
  padding: 3rem 3rem 5rem 3rem;
  width: 90%;
  z-index: 12;
  // height: 100%;
  &.testimonial {
    .left-quotes,
    .right-quotes {
      filter: none;
    }

    .right-border,
    .left-border,
    &::before,
    &::after {
      background: green;
    }
  }

  @media (max-width: $tablet_width) {
    padding: 2rem;
    width: 100%;
  }
  @media (max-width: $phone_width) {
    // padding: 3rem 3rem 5rem 3rem;
  }
  .left-quotes {
    position: absolute;
    top: 0;
    left: -20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
      hue-rotate(146deg) brightness(106%) contrast(100%);
    @media (max-width: $phone_width) {
      width: 30px;
      left: -15px;
    }
  }

  .right-quotes {
    position: absolute;
    bottom: 0;
    right: -20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
      hue-rotate(146deg) brightness(106%) contrast(100%);
    @media (max-width: $phone_width) {
      width: 30px;
      right: -10px;
    }
  }

  .right-border {
    content: '';
    position: absolute;
    width: 2px;
    background: white;
    top: 0;
    right: 0;
    height: 40%;
  }

  .left-border {
    content: '';
    position: absolute;
    width: 2px;
    background: white;
    bottom: 0;
    left: 0;
    height: 80%;
  }

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    background: white;
    top: 0;
    right: 0;
    left: 10%;
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: white;
    bottom: 0;
    left: 0;
    right: 90%;
    @media (max-width: $phone_width) {
      display: none;
    }
  }

  .description {
    margin-top: 1rem;
    min-height: 16vh;
    max-height: 22vh;
    text-align: justify;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: $phone_width) {
      max-height: 15rem;
      min-height: 10vh;
      max-height: 30vh;
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    bottom: -20px;
    right: 70px;
    // width: 400px;
    gap: 10px;

    // @media (max-width: $phone_width) {
    //   width: auto;
    //   margin-left: 10px;
    //   bottom: -50px;
    // }
  }
}

.read-more {
  span {
    text-decoration: underline;
    float: right;
    cursor: pointer;
  }
}

.closeSvg {
  filter: grayscale(100%) brightness(0%);
  cursor: pointer;
  height: 60px;
}

.header-container {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: right;
}

.desc-wrapper {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}
