@import '../../../assets/styles/sizes';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow: auto;
  padding: 2rem 0rem;
}

.modal-content {
  border-radius: 22px;
  background: #fff;
  padding: 1vw;
  // min-width: 45vw;
  position: relative;
  overflow: hidden;
  width: min-content;
  // max-width: 70vw;
  width: clamp(300px, 40vw, 1000px);
  @media (max-width: $tablet_width) {
    // max-width: 95vw;
    min-width: 90vw;
  }
  @media (max-width: $phone_width) {
    // max-width: 95vw;
    min-width: 90vw;
  }
}

.modal-body {
  max-height: 90vh;
  overflow: auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
