@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.navigator {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  margin: 0rem 2vw 0rem 1vw;
  width: 150px;
  margin-top: 50px;
  @media (-webkit-device-pixel-ratio: 1.75) {
    margin-top: 0px;
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    margin-top: 0px;
  }
  @media (max-width: $tablet_width) {
    flex-direction: row;
    width: 100%;
    margin-top: 0px;
    margin: 0rem;
    height: 100px;
  }
  @media (max-width: $phone_width) {
    height: 50px;
  }
  .step {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5vw;
    min-height: 5vw;
    background-color: $secondary_color_semi_transparent;
    border-radius: 50%;
    transition:
      width 0.3s,
      height 0.3s;
    background: #00000000 0% 0% no-repeat padding-box;
    border-radius: 64px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    span {
      cursor: pointer;
    }
    // padding: 0em 0.3rem;
    @media (max-width: $phone_width) {
      // padding: 0em 0.3rem;
      margin-top: 10px;
      min-width: 60px;
      min-height: 60px;
    }

    &.selected {
      min-width: 6vw;
      min-height: 6vw;
      background-color: $secondary_color_semi_transparent;
      background: #00000000 0% 0% no-repeat padding-box;
      border-radius: 64px;
      opacity: 1;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      @media (max-width: $phone_width) {
        min-width: 66px;
        min-height: 66px;
      }
    }
  }

  .line {
    width: 2px;
    height: 0;
    background-color: transparent;
    transition:
      background-color 0.3s,
      height 0.3s;

    &.active-line {
      height: 20vh;
      background-color: $secondary_color_semi_transparent;
      @media (-webkit-device-pixel-ratio: 1.5) {
        height: 100px;
      }
      @media (max-width: $tablet_width) {
        height: 1px;
        width: 40%;
      }
    }
  }
}
.date {
  cursor: pointer;
}
