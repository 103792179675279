@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

//this is the container of the navigation on desktop
.nav {
  display: flex;
  width: 100%;
  margin-top: 4vh;
  // flex-direction: column;
  // @media (-webkit-device-pixel-ratio: 1.25) {
  //   margin-top: 2rem;
  // }
  // @media (-webkit-device-pixel-ratio: 1.5) {
  //   margin-top: 2rem;
  // }
  @media (max-width: $tablet_width) {
    display: none;
  }
  .container {
    width: 100%;
    display: flex;
    min-height: 30vh;
    max-height: 80vh;
    gap: 100px !important;
    @media (max-width: $tablet_width) {
      gap: 1rem !important;
    }
    &:hover .menu {
      border-right: 1px solid $secondary_color_semi_transparent;
    }
  }
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-nav {
  display: none;
  @media (max-width: $tablet_width) {
    display: block;
  }
}
