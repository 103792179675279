@import '../../../../../../assets/styles/sizes';
.our-history {
  height: 100vh;
}
.navContainer {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
  height: 100vh;
  @media screen and (max-width: $tablet_width) {
    width: 85%;
  }
  @media screen and (max-width: $phone_width) {
    width: 90%;
  }
}

.yearsClass {
  flex-wrap: wrap;
}
