@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/sizes';

//this is the container of the navigation on desktop
.nav {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  max-height: 60dvh;
  overflow: hidden;
  flex-direction: column;
  overflow: auto;
  .container {
    width: 100%;
    display: flex;
    min-height: 30vh;
    max-height: 80vh;
    gap: 100px !important;
  }
}

// .rtl-nav {
//   // background-color: red;
// }
.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttons-mobile {
  display: none;
  height: max-content;
  width: 100%;
  // background-color: red;
  padding: 5vw;
  margin-top: 1rem;
  flex-direction: column;

  border-top: 1px solid $secondary_color_semi_transparent;
  @media (max-width: $phone_width) {
    display: flex;
  }
}

.button-wrapper {
  display: flex;
  margin: auto;
  gap: 2vw;
}
.gov-logo {
  margin: auto;
  width: 150px !important;
  margin-top: 1rem;
}
