@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.course-cards {
  min-height: 10vh;
  min-width: 270px;
  .cards {
    position: relative;
    border: 2rem solid rgba(0, 0, 0, 0.221);
    border-top-right-radius: 200px;
    border-top-left-radius: 200px;
    border-bottom-right-radius: 200px;
    border-bottom-left-radius: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    img {
      width: 110px;
    }
  }
  .separator {
    width: 100%;
    height: 1.5rem;
    // background-color: $disabled_color;
    border-bottom: 2rem solid rgba(0, 0, 0, 0.221);
  }
}

.header-classname {
  min-height: 250px;
  width: 100%;
}
.course-cards-mobile {
  // min-height: 10vh;
  width: 100%;
  margin-top: 2rem;
  .cards-mobile-style {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: rgba(0, 0, 0, 0.221);
    padding: 1rem 0.3rem;
    gap: 0.3rem;
    @media (max-width: $phone_width) {
      padding: 1rem 1rem;

      grid-template-columns: repeat(2, 1fr);
    }
  }
  .card-mobile {
    width: 100%;
    margin: auto;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-mobile {
    padding: 1rem;

    img {
      width: 90px;
      height: 90px;
    }
  }
  .smallerImage {
    width: 20px;
  }
}
