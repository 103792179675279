@import '../../../assets/styles/colors';

.expandable-card {
  margin: 1rem 0;
  background: #00000027 0% 0% no-repeat padding-box;
  border: 1px solid #ccccccda;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition:
    max-height 1s ease-in-out,
    opacity 1s ease-in-out;
  max-height: 100px;
  overflow: hidden;

  // opacity: 0.7;

  &:hover {
    background-color: $primary_color_Low_opacity;
    border: 1px solid $primary_color_Low_opacity;
  }
}
.card {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}
.card-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;

  transition:
    max-height 1s ease-in-out,
    opacity 1s ease-in-out;
}

.expanded {
  max-height: 500px;
  opacity: 1;
}
