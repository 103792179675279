@import '../../../../assets/styles/sizes';
.home {
  position: relative;
  width: 100%;
  min-height: 100dvh;
  max-height: 100dvh;
  background-color: rgb(0, 0, 0);
  .homeDots {
    bottom: 12vh !important;
    @media (max-width: $tablet_width) {
      bottom: 9vh;
    }
    @media (max-width: $phone_width) {
      bottom: 8vh !important;
    }
  }
}
