@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/sizes';
@font-face {
  font-family: '29LT Bukra Regular';
  src: url('../../../assets/fonts/TTF/29ltbukraregular.ttf') format('truetype');
}
.language-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #ffffff00 0% 0% no-repeat padding-box;
  border: 1px solid $secondary_color_semi_transparent;
  border-radius: 28px;
  // padding-right: 0.3rem;
  // padding-left: 0.1rem;
  transition: 0.51s ease-in-out;
  font-family: '29LT Bukra Regular';

  &:hover {
    border: 1px solid $secondary_color;
  }
}

.toggle {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  border-radius: 50px;
  font-family: '29LT Bukra Regular';
}

.language-button {
  flex: 1;
  border: none;
  // padding: 10px 0;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 50px;
  color: $disabled_color;
  padding: 0.6rem 0.5rem;
  font-size: 18px;
  font-family: '29LT Bukra Regular';

  @media (max-width: 1300px) {
    font-size: 16px;
    padding: 0.3rem 0.3rem !important;
  }
}

.language-button.active {
  font-size: 18px;
  background-color: $disabled_color;
  color: $secondary_color;
  border-radius: 50px;
  font-style: bold;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-family: '29LT Bukra Regular';

  @media (max-width: 1300px) {
    font-size: 16px;
    padding: 0.3rem 0.3rem !important;
  }
}

.language-toggle:hover .language-button.active {
  background-color: $secondary_color;
  color: $primary_color;
}

.ar {
  font-family: '29LT Bukra Regular';
}
