@import '../../../assets/styles/sizes';

.graduation-navigation {
  display: flex;
  flex-direction: column;
  display: grid;
  z-index: 2;
  .years {
    display: flex;
    flex-direction: column;
    gap: 5em;
    margin-top: 3rem;
    @media (-webkit-device-pixel-ratio: 1.5) {
      gap: 1rem;
      margin-top: 0.5rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      gap: 1rem;
      margin-top: 0rem;
    }

    @media (max-width: $tablet_width) {
      margin-top: 0.5rem;
    }
    @media (max-width: $phone_width) {
      margin-top: 1rem;
    }
  }
  .years-grid {
    display: flex;
    gap: 1.5rem;
    @media (-webkit-device-pixel-ratio: 1.5) {
      gap: 0.5rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      gap: 0.5rem;
    }
    @media (max-width: $tablet_width) {
      gap: 0.5rem;
    }
    @media (max-width: $phone_width) {
      gap: 0.3rem;
      flex-wrap: wrap;
    }
    .active {
      margin-right: 3rem;

      @media (-webkit-device-pixel-ratio: 1.25) {
        margin-right: 0rem;
        gap: 0.5rem;
      }
      @media (max-width: $tablet_width) {
        margin-right: 0rem;
        gap: 0.5rem;
      }
      @media (max-width: $phone_width) {
        margin-right: 0rem;
        gap: 0.5rem;
      }
    }
    .activeRtl {
      margin-right: 0rem;

      @media (-webkit-device-pixel-ratio: 1.5) {
        img {
          width: 50px;
        }
      }
    }
  }

  .card-container {
    position: relative;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (-webkit-device-pixel-ratio: 1.5) {
      margin-top: 0.5rem;
      gap: 1rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      margin-top: 1rem;
      gap: 1rem;
    }
    @media (max-width: 1300px) {
      gap: 1vh;
      margin-top: 1rem;
    }
    @media (max-width: $phone_width) {
      // position: absolute;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      // gap: 1rem;
      width: 100%;
      margin-top: 0.5rem;
      gap: 1vh;
    }
  }
  .gallery {
    position: relative;
    width: 55%;
    height: 100%;
  }
}
