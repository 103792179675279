@import '../../../assets/styles/colors';

.date-picker-field {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .error {
    color: red;
  }
  input {
    padding: 0.5rem;
    border: 1px solid $disabled_color_semi_transparent;
    border-radius: 5px;
    font-size: 16px;
    opacity: 0.8;
    color: $disabled_color;
    outline: none;
    background-color: transparent;
    font-family: '29LT Bukra Bold';
    height: 37px;
    text-transform: uppercase;
    &::placeholder {
      display: none !important;
    }
  }
}
