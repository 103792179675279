@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.card-container {
  display: flex;
  border-radius: 10px;
  background-color: rgba(201, 200, 200, 0.208);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(6px);

  img {
    width: clamp(2rem, 3.5vw, 6rem);
  }

  @media (max-width: $tablet_width) {
    img {
      display: none;
    }
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem 1rem 1rem;

    @media (max-width: $tablet_width) {
      padding: 0;
    }

    .rating {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .dashes-separator {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      background-image: repeating-linear-gradient(
        to right,
        $secondary_color,
        $secondary_color 12.5%,
        transparent 12.5%,
        transparent 25%
      );
      background-size: calc(100% / 2) 2px;

      @media (max-width: $tablet_width) {
        display: none;
      }
    }

    .svg-icon {
      margin-top: 3px;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
