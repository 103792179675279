@import '../../../assets/styles/colors';
.text-area {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  textarea {
    padding: 0.5rem;
    border: 1px solid $disabled_color_semi_transparent;
    border-radius: 5px;
    resize: none;
    min-height: 10vh;
    outline: none;
  }
}
.secondary {
  opacity: 0.6;
}
