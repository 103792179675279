@import '../../../assets/styles/sizes';

.container {
  padding: 7vh 5vw;
  box-sizing: border-box;

  @media (max-width: $phone_width) {
    padding: 1rem 1rem;
  }
}
