$primary_color: #008755;
$primary_color_dark: #005844;
$primary_color_Low_opacity: #00875523;
$primary_color_svg: brightness(0) saturate(100%) invert(32%) sepia(99%)
  saturate(5238%) hue-rotate(153deg) brightness(94%) contrast(101%);
$primary_color_semi_transparent: #00584429;
$primary_color_light_transparent: #00584466;
//
$secondary_color: #ffffff;
$secondary_color_semi_transparent: #ffffff61;
$secondary_color_light_transparent: #ffffff1a;
//
$disabled_color: #7e7e7e;
$disabled_color_semi_transparent: #7070705d;
$disabled_color_light_transparent: #00000029;
//
$dark: rgba(0, 0, 0, 0.6);
$black: black;
//
$bachelors_color: #67cf55;
$masters_color: #4349bc;
$phd_color: #d34637;
