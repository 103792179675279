@import '../../../../assets/styles/sizes';

.academy-in-number {
  background: transparent;
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  justify-content: center;
  background-attachment: fixed;
  overflow: hidden;
  @media (max-width: $tablet_width) {
    align-items: flex-start;
  }
  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-repeat: no-repeat;
  }
  .academy {
    width: 90%;
    display: flex;
    margin: 0rem 8rem;
    gap: 30px;
    margin-top: 5rem;
    @media (-webkit-device-pixel-ratio: 1.75) {
      gap: 0px;
      margin: 0rem 3rem;
      margin-top: 5rem;
    }
    @media (max-width: $tablet_width) {
      flex-direction: column;
      margin: 0rem 0rem;
      margin-top: 11vh;
      gap: 0px;
    }
    @media (max-width: $phone_width) {
      flex-direction: column;
      margin-top: 90px;
      width: 95%;
    }
    @media (max-height: $device_with_less_height) and (min-width: 1280px) {
      margin: 0rem 2rem;

      margin-top: 5rem;
    }
    .left {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 10px 0px;
      @media (max-width: $tablet_width) {
        flex-direction: column;
        width: 100%;
        padding: 0px 0px;
      }
      .header {
        @media (max-width: $tablet_width) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-top: 2rem;

        .text {
          margin-top: 100px;
          @media (max-width: $tablet_width) {
            margin-top: 40px;
          }
          @media (max-width: $phone_width) {
            display: none;
          }
        }
        .button {
          @media (max-width: $tablet_width) {
            display: none;
          }
        }
      }
    }
    .right {
      display: flex;
      width: 60%;
      height: 100%;
      padding: 10px;
      @media (max-width: $tablet_width) {
        width: 100%;
        padding: 0px;
        margin-top: 20px;
      }
      @media (max-width: $phone_width) {
        margin-top: 0px;
      }
    }
  }
}
.button-mobile {
  @media (min-width: $tablet_width) {
    display: none;
  }
  @media (max-width: $tablet_width) {
    display: block;
    margin-top: 2rem;
  }
}
.rtl {
  text-align: right;
}
