.flipbook-container {
  background-color: rgba(27, 25, 25, 0.908);
  position: relative;
  display: flex;
  align-items: center;
  // padding-top: 5vh;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  @media (-webkit-device-pixel-ratio: 1.25) {
    overflow: auto;
  }
}

.prev-button,
.next-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.prev-button {
  left: 1vw;
}

.next-button {
  right: 1vw;
}

.page img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.blank-page {
  display: none;
}

.flipbook {
  height: 100%;
  position: relative !important;
  margin-top: 5vh;
  img {
    background-color: white;
  }
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
