@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/sizes';

.Step-six {
  box-sizing: border-box;
  position: relative;
  display: flex;
  // flex-direction: column;
  width: 85%;
  min-height: 100px;
  margin: auto;
  margin-top: 3rem;
  padding-left: 2rem;
  @media (max-width: $tablet_width) {
    flex-direction: column;
    gap: 2rem;
    margin-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 5rem;
  }
}

.payment-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  @media (max-width: $tablet_width) {
    width: 100%;
    gap: 2rem;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 85%;
  @media (max-width: $tablet_width) {
    width: 100%;
  }
}
.dubai-numbers {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid $disabled_color_semi_transparent;
  padding: 4rem 2rem;
  max-width: 600px;
  width: 80%;
  border-radius: 5px;
  position: relative;
  @media (max-width: $tablet_width) {
    width: 100%;
    img {
      width: 300px;
    }
  }
  @media (max-width: $phone_width) {
    img {
      width: 200px;
    }
  }
  // .lottie-container {
  //   // position: absolute;
  //   // z-index: 1;
  //   // background-color: red;
  // }
}

.invoice-container {
  box-sizing: border-box;
  width: 50%;
  position: relative;
  display: flex;
  // align-items: center;
  @media (max-width: $tablet_width) {
    width: 80%;
    margin: auto;
  }
  justify-content: center;
  img {
    position: absolute;
    @media (max-width: $phone_width) {
      width: 100%;
      height: 100%;
    }
  }
  .invoice {
    width: 455px;
    box-sizing: border-box;
    padding: 0rem 3rem;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: $tablet_width) {
      width: 100%;
      margin: auto;
      padding: 0rem;
    }
    .details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 350px;
      @media (max-width: $tablet_width) {
        margin: auto;
        width: 80%;
      }
      @media (max-width: $phone_width) {
        height: 100%;
        margin: auto;
      }

      .amount {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        @media (max-width: $phone_width) {
          margin-top: 1rem;
        }
        .info {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    // border-top: 1px dashed $disabled_color_semi_transparent;
    margin-right: 8px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      @media (max-width: $tablet_width) {
        margin: auto;
        width: 80%;
        gap: 0.1rem;
      }
      @media (max-width: $phone_width) {
        margin: auto;
        margin-top: 3.5rem;
        text-align: center;
      }
    }
  }
}
