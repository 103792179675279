@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/sizes';

.side-modal {
  position: fixed;
  top: 0;
  right: -100%;
  width: 40%;
  height: 100dvh;
  background-color: white;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.5);
  transition: right 0.5s ease-in;
  background: #000000b3 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 999;
  // background-color: red;
  @media (max-width: 1251px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }

  .rtl {
    text-align: right;
  }
}
.title {
  @media (max-width: $phone_width) {
    font-size: 22px !important;
  }
}
.side-modal.open {
  right: 0;
}

.modal-content {
  padding: 4vh 5vw;
  @media (max-width: $phone_width) {
    padding: 10px 20px;
    max-height: 100vh;
    overflow: scroll;
  }
  .rtl-header {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .modal-header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $secondary_color_semi_transparent;
    padding-bottom: 0.55rem;
  }
  .modal-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 100%;
    // height: 70px;
    @media (max-width: $phone_width) {
      height: 25px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.close-button:focus {
  outline: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  cursor: pointer;

  @media (max-width: 1251px) {
    width: 20%;
  }
  @media (max-width: 1000px) {
    width: 10%;
  }
}

.backdrop.open {
  visibility: visible;
  opacity: 1;
}

//top to bottom
.side-modal-top {
  position: fixed;
  top: -100%;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  transition: top 0.5s ease-in;
  background: #000000b3 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 11;
  &.open {
    top: 0;
  }
}

.closeSvg {
  img {
    min-width: 30px;
    max-width: 70px;
    width: 3vw;
  }
}
