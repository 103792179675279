@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';
.ltr {
  direction: ltr;
}
.list-container {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  margin-top: 0.8rem;
  li {
    .text {
      color: black;
    }
    &::marker {
      font-weight: bold;
      color: $dark;
      opacity: 0.7;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  gap: 0.5rem;
  @media (max-width: $tablet_width) {
    width: 100%;
    flex-direction: column;
  }
}

.list-item {
  width: calc(25% - 10px);
  box-sizing: border-box;
  @media (max-width: $tablet_width) {
    width: 100%;
  }
}

.wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

// .item {
//   // font-size: 16px !important;
//   // @media (max-width: $tablet_width) {
//   //   font-size: 14px !important;
//   // }
