@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.header {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  padding: 1vh 2vw;
  @media (max-width: $tablet_width) {
    padding: 1vh 2vw;
  }
  @media (max-width: $phone_width) {
    padding: 0.5vh 0vw;
  }
  &.fixed {
    position: fixed;
  }

  .prime-logo {
    cursor: pointer;
    // width: 250px;
    // height: 58px;
    @media (max-width: 1300px) {
      width: 130px;
    }
    @media (max-width: $phone_width) {
      height: 40px;
    }
  }
  .dubaiPolice-logo {
    cursor: pointer;
    // width: 130px;
    // height: 48px;
    @media (max-width: 1300px) {
      width: 120px;
    }
    @media (max-width: $phone_width) {
      width: 100px;
      height: 40px;
    }
  }

  .gov-logo {
    cursor: pointer;

    cursor: pointer;
    @media (max-width: 1300px) {
      width: 115px;
    }
    @media (max-width: $phone_width) {
      width: 90px;
    }
  }
  @media (max-width: $tablet_width) {
    flex-direction: column;
  }
}
.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: $phone_width) {
    gap: 0.2rem;
  }
  img {
    @media (max-width: $phone_width) {
      width: 0px;
    }
  }

  .dash-logo {
    height: 80%;
  }
}
.header-actions {
  height: 65px;
  @media (max-width: $phone_width) {
    display: none;
  }
  @media (max-width: $tablet_width) {
    width: 100%;
    border-bottom: 1px solid $secondary_color_semi_transparent;
    // margin-left: 4vw;
    // margin-right: 4vw;

    padding-bottom: 5px;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    padding: 5px 0px;
    // height: 100%;
    border-bottom: 1px solid $secondary_color_semi_transparent;
    @media (max-width: 1300px) {
      height: 50px;
    }
    @media (max-width: $tablet_width) {
      border-bottom: 0px solid $secondary_color_semi_transparent;
    }
    @media (max-width: $phone_width) {
      height: 30px;
    }
    .language-toggle {
      @media (max-width: $phone_width) {
        display: none;
      }
    }
    .language-toggle {
      height: min-content;
    }
    .signin {
      padding: 1vh 1vw;
    }
    .buttons {
      display: flex;
      gap: 0.2vw;
      justify-content: center;
      align-items: center;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
  }
}
.header-container {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  flex-wrap: wrap;
  @media (max-width: $tablet_width) {
    width: 100%;
    border-bottom: 1px solid $secondary_color_semi_transparent;
    // margin-right: 4vw;
  }
  .logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1vw;
    // height: 60px;
    border-bottom: 1px solid $secondary_color_semi_transparent;
    padding: 5px 0px;
    height: 83px;

    @media (max-width: 1300px) {
      height: 50px;
    }
    @media (max-width: $tablet_width) {
      border-bottom: 0px solid $secondary_color_semi_transparent;
    }
    @media (max-width: $phone_width) {
      display: flex;
      justify-content: space-between;
      align-items: none;
    }
  }
  .navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5vw;
    flex-wrap: wrap;
    padding-top: 0.2rem;
    @media (max-width: $tablet_width) {
      display: none;
    }
    .link {
      // font-weight: 400;
      &:hover {
        cursor: pointer;
        color: $primary_color;
      }
    }
    .button {
      img {
        width: clamp(30px, 3vw, 45px);
        height: clamp(30px, 3vw, 45px);
      }
    }
  }
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: $phone_width) {
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 90%;
  }
}
.accessibility-container {
  display: flex;
  flex-direction: column;
  gap: 3.5vw;
  margin-top: 3.5vw;
}

.search-container {
  margin-top: 2vw;
  max-height: 80dvh;
  overflow: scroll;
  overflow-x: hidden;

  .input {
    input {
      border: 1px solid $secondary_color;
      color: $secondary_color;
    }
  }
}

// mobile stuff
.button-wrapper {
  display: flex;
}
.lang-container-mobile {
  display: none;
  width: min-content;
  @media (max-width: $tablet_width) {
    display: block;
  }
}

.buttons-tablet {
  display: none;
  @media (max-width: $tablet_width) {
    display: flex;
    margin-left: 3vw;
    margin-right: 3vw;
    justify-content: space-between;

    img {
      width: clamp(10px, 5vw, 60px);
    }
  }
  @media (max-width: $phone_width) {
    display: none;
  }
}

.active {
  color: $primary_color;
  // transition: 0.3s all ease-in-out;
  // font-weight: 900 !important;
}

.mobile-search-svg {
  display: none;
  @media (max-width: $phone_width) {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
    }
  }
}
