@import '../../../../assets/styles/colors';

.testimonial-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background: transparent
    radial-gradient(
      closest-side at 8% 24%,
      #008755 0%,
      #00754f 15%,
      #005844 100%
    )
    0% 0% no-repeat padding-box;
  // .titleClass {
  //   top: 15vh !important;
  // }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.418);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
