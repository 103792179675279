.profile-carousel {
  position: relative;
  width: 100%;
  // min-height: 300px;
  margin-top: 1.1rem;
  .carousel-wraper {
    display: flex;
    .image {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.dotsClassname {
  left: 0 !important;
}

.slides {
  align-items: none !important;
}
