@import '../../../assets/styles/sizes';

.media-card {
  position: relative;
  display: flex;
  flex-shrink: 0;
  min-height: 195px;
  cursor: pointer;

  .headings {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.318);
    align-self: flex-end;
    display: flex;
    min-height: 80px;
    flex-direction: column;
    padding: 1.5rem 1rem;
    border-radius: 0 0 10px 10px;

    .text {
      text-transform: uppercase;
      // cursor: pointer;
    }
  }
}

.album-card {
  position: relative;
  display: flex;
  height: 195px;
  .backImage {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .expand {
    position: absolute;
    bottom: 5px;
    right: 10px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    :hover {
      transform: scale(1.1);
    }
    img {
      transition: 0.3s ease-in-out;
      z-index: 999;

      width: 25px;
    }
  }

  .expandedImage {
    position: absolute;
    width: 100%;
    height: 60%;
    img {
      width: 100%;
      height: 300px;
    }
  }
  .heading {
    position: absolute;
    width: 100%;
    height: 80px;
    align-self: flex-end;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.318);
    border-radius: 0 0 10px 10px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.356);
}

.modalBody {
  padding: 0px;
}
.dialogClassName {
  width: auto;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;

  &.animateIn {
    animation: fadeInContent 0.5s forwards;
  }

  &.animateOut {
    animation: fadeOutContent 0.5s forwards;
  }
  @media (max-width: $tablet_width) {
    width: 650px;
  }
  @media (max-width: $phone_width) {
    width: fit-content;
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 1rem;
  z-index: 1001;
  cursor: pointer;
  @media (max-width: $tablet_width) {
    width: 40px;
  }
  @media (max-width: $phone_width) {
    width: 40px;
  }
}
.expandedImage {
  max-width: 100%;
  max-height: 70vh;
  object-fit: cover;
  border-radius: 3px;
  z-index: 1000 !important;
}
