@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  max-height: 100px;
  bottom: 0px;
  z-index: 0;
  opacity: 1;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  z-index: 1;

  @media (-webkit-device-pixel-ratio: 1.25) {
    height: 9vh;
  }
  // @media (max-height: $device_with_less_height) {
  //   height: 5rem;
  // }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    width: 200px !important;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.192);
    padding: 1rem;
    text-transform: uppercase;
  }
  @media (max-width: $tablet_width) {
    display: none;
  }
}

.navigationsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  // width: 80%;
}

.navigationsContainer > * {
  flex: 1;
}

.disableResponsive {
  @media (max-width: $tablet_width) {
    display: flex !important;
    flex-direction: column;
    .navigationsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
