@import '../../../../assets/styles/sizes';

.footer {
  position: relative;
  width: 100%;
  display: flex;
  background-color: #333;
  background: transparent
    radial-gradient(
      closest-side at 8% 24%,
      #008755 0%,
      #00754f 15%,
      #005844 100%
    )
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00584429;
  @media (max-width: $tablet_width) {
    flex-direction: column;
  }
  a {
    text-decoration: none;
  }
  .common-text {
    text-transform: uppercase;
    cursor: pointer;
    font-weight: '100';
  }
  .image {
    padding: 4rem 0rem 0rem 0rem;
    margin-left: 6rem;
    cursor: pointer;
    @media (max-width: $tablet_width) {
      margin-left: 4rem;
    }
    @media (max-width: $phone_width) {
      margin-left: 2.5rem;
      padding-top: 1rem;

      img {
        width: 100px;
      }
    }
  }
  .titles-subs {
    width: 100%;
    display: flex;
    padding: 4rem;
    flex-direction: column;
    margin-right: 6rem;
    @media (max-width: $tablet_width) {
      flex-direction: column;
      padding: 4rem;
    }
    @media (max-width: $phone_width) {
      padding: 1rem 1rem 1rem 0.5rem;
    }
  }
  .dpa-links {
    // width: 50%;
    @media (max-width: $tablet_width) {
      width: 70%;
    }
    @media (max-width: $phone_width) {
      width: 100%;
    }
  }
  .grid-container {
    width: 100%;
    display: flex;
    gap: 3rem;

    .grid {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      @media (max-width: $tablet_width) {
        margin-top: 0px;
      }
    }
    @media (max-width: $tablet_width) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    gap: 0.5rem;
    @media (max-width: $phone_width) {
      margin-top: 10px;
    }
  }
  .right-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: $tablet_width) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
    }
    .social-media {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      .svgs {
        display: flex;
        gap: 1rem;
        img {
          @media (max-width: $phone_width) {
            width: 40px;
          }
        }
      }
    }
    .phone-play-svgs {
      display: flex;
      gap: 3rem;
      img {
        @media (max-width: $phone_width) {
          width: 40px;
        }
      }
    }
  }
}
.web-rights {
  width: 100%;
  // position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  background: rgb(9, 79, 52) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px black;
  padding: 10px;
  gap: 20px;
  box-shadow: 0px 3px 6px #00584429;
  @media (max-width: $phone_width) {
    flex-direction: column;
    padding: 0.5rem 2.5rem;
    gap: 2px;

    .text {
      font-size: 10px;
      color: white;
    }
  }
  .text {
    font-size: 13px;
    color: white;
    @media (max-width: $phone_width) {
      font-size: 10px;
      color: white;
    }
  }
  .link {
    font-size: 14px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 100;
    @media (max-width: $phone_width) {
      font-size: 10px;
      color: white;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .policies {
    display: flex;
    // flex-direction: column;
    gap: 4rem;
    @media (max-width: $phone_width) {
      flex-direction: column;
    }
  }
}
.mobile-con {
  width: 100%;
  height: 60px;
  background: rgb(9, 79, 52) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px black;
  padding: 10px;
  gap: 20px;
  box-shadow: 0px 3px 6px #00584429;
  @media (min-width: $tablet_width) {
    display: none;
  }
}

.footer-logo {
  width: 250px;
  height: 60px;
  @media (max-width: $phone_width) {
    display: none;
  }
}
.twit {
  width: 50px;
}
