@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';
.modalClass {
  width: clamp(340px, 30vw, 1000px) !important;
  min-width: 300px;
  @media (max-width: $tablet_width) {
    min-width: 70%;
  }
}
.apply-now {
  padding: 2rem;

  @media (max-width: $tablet_width) {
    min-width: 80%;
  }
  @media (max-width: $phone_width) {
    padding: 1rem;
  }
  .logo {
    display: flex;
    justify-content: center;
    height: 110%;
    filter: brightness(0) saturate(100%) invert(36%) sepia(74%) saturate(6261%)
      hue-rotate(151deg) brightness(91%) contrast(101%);
  }
  .actions {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 14px;
    min-height: 100px;
    .radio-container {
      display: flex;
      gap: 10px;
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        input {
          width: 21px;
          height: 21px;
        }
      }
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      // margin-bottom: 2rem;
    }
    .text-container {
      display: flex;
      gap: 10px;
      .text {
        &:hover {
          cursor: pointer;
          color: $primary_color !important;
        }
      }
    }
  }
}
