@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';

.button {
  width: 100%;
  // height: 70px;
  color: $secondary_color;
  border: none;
  padding: 20px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(24px);
  display: flex;
  justify-content: center;
}

.primary {
  background-color: rgba(255, 255, 255, 0.164);

  &:hover {
    background-color: $secondary_color_semi_transparent;
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.164);
  }
}

.secondary {
  background-color: $primary_color_dark;
  padding: 15px;
  border: none;
  &:hover {
    background-color: $primary_color;
  }
}

.noOultine {
  background-color: white;
}
.black {
  background: #767575 0% 0% no-repeat padding-box;
  padding: 1rem 2rem;
  &:hover {
    background-color: black;
  }
}

.buttonLoader {
  height: 48px;
  gap: 0.5rem;
  display: flex;
}

.buttonDisabled {
  background-color: $primary_color !important;
}
.secondaryLoader {
  &.secondary {
    padding: 3px;
  }
}
