@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1;
  padding: 0;
  height: 40px;

  img {
    @media (max-width: 1300px) {
      width: 40px;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    height: 40px;
  }
  @media (max-width: $tablet_width) {
    display: none;
  }
  .clear-button,
  .svg-container {
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
  }

  .search-dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 10;
    background: inherit;
    background: #ffffff1a 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
    max-height: 50vh;
    overflow-x: hidden !important;
    // overflow: scroll;
    .item {
      cursor: pointer;
    }
  }

  // .search-dropdown::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: inherit;
  //   backdrop-filter: brightness(12) blur(24px);
  //   z-index: -1;
  // }

  // .search-dropdown {
  //   background: #000000b3 0% 0% no-repeat padding-box;
  //   opacity: 1;
  // }

  .clear-button {
    top: 48%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    font-size: 1rem;
    color: $secondary_color;
    margin-right: 20px;
    height: 100%;
    width: 20px;
    z-index: 1;
  }

  .input {
    overflow: hidden;
    width: 0;
    transition: width 0.3s ease-out;

    &[data-active='true'] {
      width: 100%;
      outline: none;
      border: 1px solid transparent;

      input {
        width: 100%;
        outline: none;
        border: 1px solid $secondary_color_semi_transparent;
        border-radius: 30px;
        background-color: transparent;
        margin: 0;
        height: 100%;
        // height: 50px;
        font-size: 14px;
        color: $secondary_color_semi_transparent;
        padding-left: 20px;
        caret-color: $secondary_color;

        &::placeholder {
          color: white;
          opacity: 0.6;
        }
      }
      &.with-dropdown input {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
      }
    }

    input {
      caret-color: $secondary_color;
      border: none;
      padding: 10px;
      box-sizing: border-box;
      width: 0;
      transition: width 0.3s ease-out;
      border-radius: 30px;
      background-color: transparent;
      height: 100%;
      margin: 0;
      border: 1px solid $secondary_color_semi_transparent;
    }
  }

  .svg-container {
    transition: opacity 0.3s ease-out;
    cursor: pointer;
  }

  .input[data-active='true'] + .svg-container {
    opacity: 0;
    pointer-events: none;
  }
}

.unanimated-search {
  position: relative;
  // flex: 1;
  // height: 100%;
  padding: 0;
}

.clear-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  font-size: 1rem;
  color: $black;
  margin-right: 20px;
  height: 100%;
  width: 20px;
  z-index: 1;
  cursor: pointer;
  color: white;
}
.clear-button-rtl {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  font-size: 1rem;
  color: $black;
  margin-right: 20px;
  height: 100%;
  width: 20px;
  z-index: 1;
  cursor: pointer;
  color: white;
}

.input {
  overflow: hidden;
  width: 100%;
  outline: none;
  border: 1px solid transparent;

  input {
    width: 100%;
    outline: none;
    border: 1px solid $primary_color;
    border-radius: 30px;
    background-color: transparent;
    margin: 0;
    height: 100%;
    height: 50px;
    font-size: 20px;
    color: $dark;
    padding-left: 20px;
    padding: 20px;
    box-sizing: border-box;
  }
}

// .search-dropdown-mobile {
// }
