@import url('../../../../assets/styles/_sizes.scss');

.year_li span {
  opacity: 0;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.year_li span:focus,
.year_li:hover span,
.year_li.year_bar_active span,
.year_li:hover .year_bar_long + span,
.year_li:hover .year_bar_short + span,
.year_bar_long:hover + span,
.year_bar_short:hover + span {
  opacity: 1 !important;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.year_bar_long {
  border-radius: 31px 0px 0px 31px;
  background: #fff;
  height: 20px;
  width: 100px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.year_bar_long:focus,
.year_bar_long:hover,
.year_li.year_bar_active .year_bar_long {
  background-color: #0fa06e !important;
  width: 120px;
  transition: 0.3s ease-in-out;
}

.year_bar_active {
  background-color: #0fa06e !important;
  width: 120px;
  border-radius: 31px 0px 0px 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.year_bar_short {
  border-radius: 31px 0px 0px 31px;
  background: #fff;
  height: 20px;
  width: 70px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.year_bar_short:focus,
.year_bar_short:hover,
.year_li.year_bar_active .year_bar_short {
  background-color: #0fa06e !important;
  width: 100px;
  transition: 0.3s ease-in-out;
}

.bar_ul {
  position: fixed;
  display: flex;
  justify-content: center;
  margin: 0px;
  padding-left: 24px;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.49) 0%,
    rgba(0, 0, 0, 0) 69.96%
  );
  flex-direction: column;
  width: 16%;
  align-content: flex-end;
  height: 100%;
  transition: 0.3s ease-in-out;
}

.year_li {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
  color: #fff;
  padding: 3px 0;
  cursor: pointer;
  gap: 1rem;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.dashes-image {
  width: 10px;
  transition: 0.3s ease-in-out;
  user-select: none;
}

@media (max-width: 1200px) {
  .year_bar_long {
    height: 60px;
    width: 15px;
    margin-left: 0;
    border-radius: 0px 0px 31px 31px;
  }

  .year_bar_long:focus,
  .year_bar_long:hover,
  .year_li.year_bar_active .year_bar_long {
    width: 20px;
    height: 50px;
  }

  .year_bar_active {
    width: 20px;
    height: 80px;
    border-radius: 0px 0px 31px 31px;
  }

  .year_bar_short {
    height: 50px;
    width: 15px;
    margin-left: 0;
    border-radius: 0px 0px 31px 31px;
  }

  .year_bar_short:focus,
  .year_bar_short:hover,
  .year_li.year_bar_active .year_bar_short {
    width: 20px;
    height: 70px;
  }

  .bar_ul {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding-left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.49) 0%,
      rgba(0, 0, 0, 0) 69.96%
    );
  }

  .year_li {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.2rem;
    padding: 0;
  }

  .year_li span {
    font-size: 12px;
  }
}
