@import '../../../../../../assets/styles/sizes';
@import '../../../../../../assets/styles/colors';

.our-history {
  position: relative;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 2s all ease-in-out;
  z-index: 0;
  overflow: hidden !important;

  .text-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      .text {
        color: $primary_color;
      }
    }
  }
}

.history-svg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease-in-out;
  margin-top: 5rem;
  transition: 1.5s all ease-in-out;

  @media (min-width: $phone_width) {
    margin-top: 5rem;

    &.animate-in {
      margin-top: 12rem;

      transform: translateX(35vw);
      @media (max-height: $device_with_less_height) and (min-width: $tablet_width) {
        margin-top: 12rem;
        transform: translateX(50%);
      }
      @media (max-width: $tablet_width) {
        margin-top: 12rem;
        transform: translateX(30%);
      }
      img {
        transition: 1s all ease-in-out;
      }
    }
  }

  img {
    width: 85%;
    transition: transform 1s ease-in-out;
    @media (max-height: $device_with_less_height) and (min-width: $tablet_width) {
      width: 55%;
    }
    @media (max-width: $tablet_width) {
      width: 70%;
    }
    @media (max-width: $phone_width) {
      width: 100%;
    }
  }
}
.container {
  // margin-top: 5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  transition: justify-content 1s ease-in-out;
}

.container.animate-in {
  justify-content: flex-end !important;
}
