@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';

.input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 5px;

  input {
    padding: 0.3rem;
    // font-size: 20px;
    opacity: 0.8;
    color: $disabled_color;
    transition: 0.3s all ease-in-out;
    &:focus {
      outline: none;
    }
  }
  .placeholder {
    position: absolute;
    bottom: 5px;
    color: #00000099;
    .required {
      color: black !important;
      font-size: 20px;
    }
  }

  .type {
    position: absolute;
    right: 0;
    top: 8px;
    background-color: white;
    height: 30px;
    .text {
      font-size: 16px;
      color: #f3223c;
      cursor: pointer;
      text-transform: uppercase;
      opacity: 0.6;
      &:hover {
        color: $primary_color;
      }
    }
  }
  .type-rtl {
    top: 18px;
    min-width: 40px;
    right: auto;
    left: 0 !important;
  }
  .form-field-type {
    position: absolute;
    right: 10px;
    top: 35px;
    background-color: white;
    // height: 30px;
    .text {
      color: #f3223c;
      opacity: 0.6;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        color: $primary_color;
      }
    }
  }
}

.primary {
  width: auto;
  padding: 0.7rem;
  background-color: $secondary_color;
  outline: none;
  border: none;
  opacity: 0.7;
  border-radius: 5px;
}

.secondary {
  width: auto;
  padding: 0.3rem !important;
  border: none;
  outline: none;
  border-bottom: 1px solid $disabled_color_semi_transparent;
  background-color: transparent;
  min-height: 35px;
  &::placeholder {
    font-size: 15px;
    opacity: 0.4;
    font-family: '29LT Bukra Bold';
  }
  &:focus {
    outline: none;
    border-bottom: 0.3px solid $primary_color;
  }
}

.formField {
  padding: 0.35rem !important;
  border: 1px solid $disabled_color_semi_transparent;
  border-radius: 5px;
  height: 37px;
  display: flex;
  justify-content: center;
}
.input-error {
  border-color: #f3223c;
}

.formField-white {
  padding: 0.35rem !important;
  border: 1px solid $disabled_color_semi_transparent;
  border-radius: 5px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  opacity: 0.6 !important;
  color: black !important;
  font-weight: 600 !important;
  height: 37px;
  display: flex;
  justify-content: center;
}
