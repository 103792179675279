@import '../../../assets/styles/sizes';

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.5vw;
  // width: 100%;

  img {
    width: clamp(5vw, 10vw, 50px);
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);

    > *:nth-last-child(-n + 2) {
      display: none;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    grid-template-columns: repeat(4, 1fr);
    img {
      display: none;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: $tablet_width) {
    height: 40vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    > :first-child {
      display: none;
    }
  }
}
.first-card-responsive {
  @media (min-width: $tablet_width) {
    display: none;
  }
  @media (max-width: $tablet_width) {
    margin-bottom: 10px;
    .first-div-mobile {
      height: 130px;
    }
  }
  @media (max-width: $phone_width) {
    .first-div-mobile {
      height: 60px;
    }
  }
}
.grid {
  width: 100%;
}
