@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.radio-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.3s ease-in-out;

  .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    flex-shrink: 0;
    min-width: 0;
  }

  .text {
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .radio-con {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    input[type='radio'] {
      width: 1em;
      height: 1em;
      min-width: 20px;
      min-height: 20px;
      appearance: none;
      border: 1px solid $black;
      border-radius: 50%;
      outline: none;
      margin-top: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 1em;
        height: 1em;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        background: transparent;
        transition:
          background-color 0.2s,
          transform 0.3s ease;
      }

      &:hover:before {
        background-color: lighten($black, 40%);
      }

      &:checked {
        &::before {
          background-color: $black;
        }
      }
    }
  }

  .column {
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media (max-width: $tablet_width) {
      flex-direction: column;
    }
  }

  &.default {
    .radio-con input[type='radio']:before {
      border: 2px solid $black;
    }
  }

  &.secondary {
    .radio-con input[type='radio'] {
      border-color: white;

      &:hover:before {
        background-color: lighten(white, 40%);
      }

      &:checked:before {
        background-color: white;
      }
    }
  }
}
