@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.avatar-container {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  // width: 120px;
  align-items: center;

  @media (max-width: $tablet_width) {
    margin-top: -3rem !important;
  }
  button {
    outline: none;
    background-color: transparent;
  }
}
.avatar-rtl {
  background-color: red;
}
.con {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.avatar-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.avatar-container-showing {
  opacity: 1;
}
.profile {
  position: relative;

  &:hover .profile-image {
    transform: scale(1.2);
    filter: brightness(1.2);
  }

  @media (max-width: $tablet_width) {
    transform: rotate(-90deg);
  }
}
.scrollButton {
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px !important;
    height: 30px;
  }
  .up {
    transform: rotate(180deg);
  }
}
.profile-image {
  position: relative;
  width: clamp(50px, 5vw, 60px);
  height: clamp(50px, 5vw, 60px);
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $disabled_color_light_transparent;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  filter: grayscale(100%);

  @media (max-width: $tablet_width) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: $phone_width) {
    width: 40px;
    height: 40px;
  }
}

.profile.active .profile-image {
  transform: scale(1.3);
  filter: grayscale(0%);
}

.profile-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}

.num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
@keyframes avatarEnter {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes avatarExit {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.avatar-enter {
  animation: avatarEnter 0.5s ease-out forwards;
}

.avatar-exit {
  animation: avatarExit 0.5s ease-out forwards;
}
.upDisabled {
  filter: brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(622%)
    hue-rotate(135deg) brightness(79%) contrast(74%);
  cursor: default;
}
