@import '../../../../../../../assets/styles/sizes';
.media-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.media {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
  width: 100%;
  .card {
    @media (max-width: $phone_width) {
      height: 50px;
      min-height: 140px;
    }
  }
}
.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0rem;
  text-transform: uppercase;

  // width: max-content;
  @media (max-width: $tablet_width) {
    display: none !important;
  }
  .text {
    cursor: pointer;
  }
}
.view-all-button {
  display: none;
  margin-top: 1rem;
  padding: 1rem 0rem;
  text-transform: uppercase;

  @media (max-width: $tablet_width) {
    display: flex;
  }
}
