.downloadButton {
  position: relative;
  padding: 1.5vh 2vw;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &.black .buttonImage {
    filter: brightness(0) invert(1);
  }

  &.black:hover::before {
    border-color: rgba(0, 0, 0, 0.298);
  }

  .buttonImage {
    width: 30px !important;
    height: 30px !important;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(13%)
      hue-rotate(214deg) brightness(104%) contrast(105%);
  }
  .text {
    cursor: pointer;
  }

  &:hover {
    transition: 0.3s all ease-in-out;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 30px;
    border: 0px solid rgba(255, 255, 255, 0.298);
    transition: width 0.3s ease-in-out;
  }

  &:hover::before {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.298);
  }
}

.black {
  span {
    color: rgb(0, 0, 0);
  }
  // border: 1px solid rgba(3, 3, 3, 0.298);

  img {
    filter: brightness(0) saturate(100%) !important;
  }
}
