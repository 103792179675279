@import '../../../assets/styles/sizes';

.svg-container {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 230px;
  align-items: center;
  // @media (max-width: 380px) {
  //   flex-direction: column;
  //   align-items: flex-start;
  //   min-width: 10px;
  // }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .image {
    height: 3rem;
    @media (max-width: $tablet_width) {
      height: 2rem;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}
