@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/sizes';

.Find-your-program {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-attachment: fixed;
  overflow: hidden;
  @media (max-width: $phone_width) {
    background-position: none !important;
  }
  .imageClass {
    background-position: 10% 55% !important;
  }
  .toggle-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 4rem;
    max-width: 1000px;
    margin: auto;
    margin-top: 4rem;
    @media (-webkit-device-pixel-ratio: 1.25) {
      max-width: 800px;
    }

    @media (max-width: $tablet_width) {
      display: none;
    }
  }
  .program {
    width: 60%;
    max-width: 1000px;
    text-align: center;
    @media (max-width: 1524px) {
      width: 70%;
    }
  }
}

.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.toggle-mobile {
  display: none !important;

  @media (max-width: $tablet_width) {
    display: flex !important;
  }
}
@keyframes smoothZoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
