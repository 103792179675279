@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';

.toggle-list {
  .toggle-text {
    cursor: pointer;
    display: inline-block;
    transition:
      color 0.3s ease,
      border-bottom 0.3s ease;
    user-select: none;
    border-bottom: 4px solid transparent;

    &:hover {
      color: $primary_color;
      border-bottom: 4px solid $primary_color;
    }
  }

  ul {
    padding: 0rem;
  }

  .list {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    pointer-events: none;
    min-height: 0;
    overflow: hidden;
    list-style-type: none;
    transition:
      opacity 0.3s ease,
      max-height 0.3s ease-in-out;
    border-bottom: 1px solid $primary_color;

    li {
      text-decoration: none;
      padding: 0.5rem 0px;
      transition: color 0.3s ease;
      user-select: none;
      margin: 0px;
    }
  }

  .list.show {
    display: block;
    opacity: 1;
    pointer-events: auto;
    min-height: 50px;
  }
}

.text-hover {
  &:hover {
    color: $primary_color;
    cursor: pointer;
  }
}
// .left {
//   text-align: left;
// }

// .right {
//   text-align: right;
// }
.mobile {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
}

.active {
  color: $primary_color;
  border-bottom: 4px solid $primary_color !important;
}
