@import '../../../assets/styles/sizes';

.customer-modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4vh;
  justify-content: center;
  align-items: center;

  .logo {
    img {
      height: 50%;
    }
  }
  .customer-submodal {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subText {
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
  .text {
    text-align: center;
  }
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .face {
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      img {
        height: 100px;
      }
      img {
        @media (max-width: $tablet_width) {
          height: 130px;
          width: 100%;
        }
        @media (max-width: $phone_width) {
          height: 100px;
          width: 100%;
        }
      }
    }
  }
}
.modalClass {
  width: clamp(340px, 30vw, 1000px) !important;
  min-width: 300px;
  @media (max-width: $tablet_width) {
    min-width: 70%;
  }
}
