@import '../../../../../assets/styles/sizes';
.Step-one {
  .input-grid {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // margin-top: 3rem;
    .input-row {
      display: flex;
      gap: 1rem;
      @media (max-width: $tablet_width) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        > div:first-child {
          grid-column: span 2;
        }
      }
      @media (max-width: $phone_width) {
        grid-template-columns: repeat(1, 1fr);
        > div:first-child {
          grid-column: span 1;
        }
      }
    }
    .flexed {
      display: flex;
      flex: 0 1 150%;
      gap: 1rem;
      @media (max-width: $tablet_width) {
        width: 100%;
      }
      @media (max-width: $phone_width) {
        flex-direction: column;
      }
      .smaller-input {
        flex: 0 1 45%;
      }
    }

    .extra-div {
      width: 100%;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
    .list {
      font-family: '29LT Bukra Bold Italic';
      color: rgba(228, 6, 6, 0.945);
    }
  }

  .checkbox-link {
    display: flex;
    gap: 0.5rem;
    @media (max-width: $tablet_width) {
      flex-direction: column;
      gap: 0.2rem;
    }
  }
  // .requiredAssistanceSelect {
  //   width: 42%;
  //   @media (max-width: $tablet_width) {
  //     width: 100%;
  //   }
  // }

  .onResponsivePhone {
    @media (max-width: $tablet_width) {
      display: grid !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}

.employment {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: clamp(300px, 20vw, 400px);
}

.inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.input-row-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
}
