@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/sizes';
.unanimated {
  transform: translateX(0px);
}
.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  @media (max-width: 1300px) {
    img {
      width: 35px;
    }
  }
  &.animated-class {
    img {
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateX(-14px);
      }
    }
  }
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.1px 1rem;
  @media (max-width: $tablet_width) {
    padding: 0.1px 0.2rem;
  }
  @media (max-width: $phone_width) {
    min-width: 50px;
  }
  &:hover {
    border: 1px solid $secondary_color_semi_transparent;
    transition: 0.6s ease-in-out;
  }
  p {
    color: $secondary_color;
    font-family: $bukra_light;
    font-size: 22px;
  }
}

.bordered-class {
  border: 1px solid transparent;
  @media (max-width: $phone_width) {
    border: none;
  }
  &:hover {
    border: 1px solid $secondary_color_semi_transparent;
    transition: 0.6s ease-in-out;
    @media (max-width: $phone_width) {
      border: none;
    }
  }
}
