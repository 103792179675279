@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/sizes';

.navigation {
  height: 95%;
  width: 23vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  border-right: 1px solid $primary_color;
  transition: 0.3s all ease-in-out;
  min-width: 200px;
  // padding-right: 20px;
  @media (max-width: $tablet_width) {
    border-right: 1px solid transparent;
    width: 100%;
    gap: 0.5rem;
    padding-right: 0px;
  }
  .textRtl {
    display: block !important;
    text-transform: uppercase;
    padding-bottom: 0.5rem;

    cursor: pointer;
    &:hover {
      color: $primary_color;
      border-bottom: 1px solid $primary_color;
    }
    &:hover > img {
      filter: $primary_color_svg;
    }
  }

  .text {
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    padding-bottom: 0.5rem;

    gap: 0.5rem;
    &:hover > img {
      filter: $primary_color_svg;
    }
    img {
      width: 20px;
      height: 20px;
      @media (max-width: $tablet_width) {
        display: none;
      }
    }
    &:hover {
      color: $primary_color;
      border-bottom: 1px solid $primary_color;
    }
  }
}
.selected {
  transition: 0.3s all ease-in-out;
  color: $primary_color;
  border-bottom: 1px solid $primary_color !important;
  padding-bottom: 0.5rem;
  img {
    filter: $primary_color_svg;
    transform: rotate(90deg);
  }
}

.children {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-left: 1rem;
  @media (max-width: $tablet_width) {
    gap: 0.5rem;
  }
  .text {
    padding-bottom: 0.5rem;

    &:hover {
      border-bottom: 1px solid transparent !important;
    }
  }
  .selected {
    transition: 0.3s all ease-in-out;
    color: $primary_color;
    border-bottom: 1px solid transparent !important;
    img {
      background-color: red;
      rotate: (90deg);
      width: 1000px;
    }
  }
}

.contentsMobile {
  display: none;
  @media (max-width: $tablet_width) {
    display: block;
  }
}
.borders {
  border-right: 0px solid $primary_color;
  border-left: 1px solid $primary_color;
}
