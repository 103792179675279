@import '../../../assets//styles//sizes';

.messaging-container {
  position: fixed;
  bottom: 11vh;
  right: 0.5vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  transition: 0.3s all ease-in-out;

  .default-img {
    cursor: pointer;
    width: clamp(30px, 4vw, 40px);
    max-width: 50px;
    transition: 0.3s all ease-in-out;
    &:hover {
      transform: scale(0.95) translateY(-5px);
    }
    @media (max-width: $tablet_width) {
      width: 40px;
    }
    @media (max-width: $phone_width) {
      width: 30px;
      margin-right: 5px;
    }
  }
  @media (max-width: $tablet_width) {
    right: 0px;
    // bottom: 200px;
  }
  @media (max-width: $phone_width) {
    right: 0px;
    bottom: 65px;
  }
  .green-img {
    transition: 0.3s all ease-in-out;
    filter: brightness(0) saturate(100%) invert(24%) sepia(49%) saturate(3990%)
      hue-rotate(149deg) brightness(101%) contrast(101%);
  }
}

.messaging-container-mobile {
  position: fixed;
  bottom: 11vh;
  right: 2vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  transition: 0.3s all ease-in-out;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.1rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    background-color: white;
    transition: 0.3s all ease-in-out;
  }
  .m-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: 0.3s all ease-in-out;
    height: 100px;
    .default-img {
      cursor: pointer;
      width: 40px;
      opacity: 1;
      transition: 0.3s all ease-in-out;
    }
    .green-img {
      filter: brightness(0) saturate(100%) invert(24%) sepia(49%)
        saturate(3990%) hue-rotate(149deg) brightness(101%) contrast(101%);
      transition: 0.3s all ease-in-out;
    }
  }
  .m-mobile-dnone {
    transition: 0.3s all ease-in-out;
  }
  .liner {
    width: 50%;
    height: 4px;
    background-color: black;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
  }

  .linerX {
    width: 50%;
    height: 4px;
    background-color: black;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
  }
  .first {
    position: absolute;
    transform: rotate(50deg);
  }
  .second {
    position: absolute;
    transform: rotate(-50deg);
  }
  .third {
    opacity: 0;
    transform: rotate(-50deg);
    position: absolute;
  }
}

.image1,
.image2 {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: 0.2s all ease-in-out;
  opacity: 0.5;
}

.showImage1 {
  bottom: 100px;
  opacity: 1;
}

.showImage2 {
  bottom: 50px;
  opacity: 1;
}

.messaging {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
