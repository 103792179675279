.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.375);
  transform: scale(1);
  transition: 1.5s ease-in-out;
  overflow: hidden;
  &.high {
    background-color: rgba(0, 0, 0, 0.646) !important;
  }
  &.medium {
    background-color: rgba(0, 0, 0, 0.467) !important;
  }
  &.low {
    background-color: rgba(0, 0, 0, 0.252) !important;
  }
}
.scale {
  transition: 2s ease-in-out;

  transform: scale(1.1);
}

.scaleOut {
  transition: 0.5s ease-in-out;

  transform: scale(1);
}
