@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/sizes';

.stepperSection {
  width: 100%;
  height: 100vh;
  transition: 0.5s all ease-in-out;
  overflow: scroll;
  background-image: url('./backgroundImage.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  @media (max-width: 1000px) {
    background-attachment: fixed;
    background-image: url('../../../../../../assets/images/history/mobile/1988-m-bg.png');
  }
}

.stepperContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: auto;
  @media (max-width: $phone_width) {
    align-items: center;
  }
}

.stepper {
  transition: 0.5s all ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 5%;
  width: 200px;
  height: 100%;
  gap: 0.5rem;
  @media screen and (max-width: $tablet_width) {
    height: 80%;
  }
  @media screen and (max-width: $phone_width) {
    margin-left: 0% !important;
    width: 100px;
    height: 70%;
  }
}

.stepLine {
  width: 5px;
  flex-grow: 1;
  background-color: white;
  z-index: 0;
  border-radius: 20px;
  border: 0px solid transparent;
}

.step {
  width: 80px;
  height: 80px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  @media screen and (max-width: $phone_width) {
    width: 60px;
    height: 60px;
  }
}

.stepContent {
  min-width: 100px;
  display: flex;
  // align-items: center;
  opacity: 1;
  // @media screen and (max-width: $phone_width) {
  //   height: 70%;
  // }
}

.invisibleLine {
  transition: 0.5s all ease-in-out;
  opacity: 0;
}

.stepperContent {
  transition: 0.5s all ease-in-out;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 5%;
  min-width: 200px;
  max-width: 40%;
  height: 100%;
  gap: 0.5rem;
  @media screen and (max-width: $tablet_width) {
    height: 80%;
  }
  @media screen and (max-width: $phone_width) {
    height: 70%;
  }
}

.activeStep {
  background-color: $primary_color;
}
