@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
.mobile-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .form-field {
    font-size: 16px;
    color: $disabled_color;
    border-radius: 5px;
    height: 38px;
    width: 100%;
    direction: ltr;
    input {
      width: 100%;
      height: 38px;
    }
  }
  .form-field-type {
    position: absolute;
    right: 10px;
    top: 35px;
    background-color: white;
    // height: 30px;
    .text {
      color: #f3223c;
      opacity: 0.6;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        color: $primary_color;
      }
    }
  }
}

.low-opacity {
  opacity: 0.6;
}
