.table {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  .text {
    margin-bottom: 1rem;
  }
  .heading-classname {
    margin-top: 3rem;
  }
  .inner-link {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    a {
      color: blue;
    }
  }
}
