@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/sizes';

//the menu main menu
.menu {
  // width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-right: 1px solid transparent;
  // padding-right: 20px;

  .link {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 15px;
  }
}
.rtl-menu {
  .active-link {
    color: $primary_color;
    img {
      filter: brightness(0) saturate(100%) invert(12%) sepia(92%)
        saturate(2071%) hue-rotate(126deg) brightness(93%) contrast(88%);
      transition: 0.3s all ease-in-out;
      @media (max-width: $tablet_width) {
        transform: rotate(90deg);
      }
    }
  }
  .link {
    img {
      transform: rotate(180deg);
    }
  }
  .active-link {
    img {
      transform: rotate(90deg);
    }
  }
}
// the childrens
.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .sub-menu {
    display: flex;
    flex-direction: column;
    // gap: 3rem;
    .child-link {
      cursor: pointer;
      margin-bottom: 1rem;
      font-size: 13px;

      &:hover {
        color: $primary_color;
      }
    }
  }
}

//
.inner-child-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 10px;
  @media (max-width: $tablet_width) {
    gap: 1px;
  }
}

.inner-subs-container {
  // background-color: red;
  margin-top: 1rem;
}

.flexed-row {
  flex-direction: row !important;
  gap: 10% !important;
  @media (max-width: $tablet_width) {
    flex-direction: column !important;
    gap: 10px !important;
    max-height: 65vh;
    overflow: auto;
  }
}

.active-link {
  color: $primary_color;
  img {
    filter: brightness(0) saturate(100%) invert(12%) sepia(92%) saturate(2071%)
      hue-rotate(126deg) brightness(93%) contrast(88%);
    transform: translateX(5rem);
    transition: 0.3s all ease-in-out;
    @media (max-width: $tablet_width) {
      transform: rotate(90deg);
    }
  }
}

.main-child-link {
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 11px !important;
}

.link-copy {
  cursor: pointer;
  font-size: 12px !important;
}
