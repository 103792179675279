@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .carousel {
    position: relative;
    width: 100%;
    height: 100%;
    // max-height: 100vh;
    overflow: hidden;
  }
  .carousel85 {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 85vh;
    overflow: hidden;
  }
  .carousel-track {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-slide {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .carousel-slide .arrows {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    z-index: 1;
    transition: 0.3s ease;
    background-color: transparent;

    /* For tablets */
    // @media (max-width: $tablet_width) {
    //   display: none;
    // }

    /* For phones */
    // @media (max-width: $phone_width) {
    //   display: none;
    // }
  }

  .carousel-arrow.prev {
    left: 3vw;

    @media (max-width: $tablet_width) {
      img {
        width: 2rem;
      }
    }
    @media (max-width: $phone_width) {
      left: 1vw;

      img {
        width: 1rem;
      }
    }
  }

  .carousel-arrow.next {
    right: 3vw;

    @media (max-width: $tablet_width) {
      img {
        width: 2rem;
      }
    }
    @media (max-width: $phone_width) {
      right: 1vw;
      img {
        // display: none;
        width: 1rem;
      }
    }
  }

  .carousel-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    @media (max-width: $tablet_width) {
      bottom: 20%;
    }
    @media (max-width: $phone_width) {
      bottom: 80px;
      // background-color: red;
    }
    @media (min-height: $tablet_height) {
      bottom: 15%;
    }

    @media (max-height: 700px) {
      bottom: 20%;
    }
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #555;
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 15px;
      height: 15px;
    }
  }

  .dot.active {
    background: white;
  }

  .dot:hover {
    background: $primary_color;
  }

  .carousel:hover .carousel-arrow.prev,
  .carousel:hover .carousel-arrow.next {
    opacity: 1;
    pointer-events: auto;
  }

  .carousel-dots.postion {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 150px;
  }
}

.hide {
  display: none;
}
