@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.separator-container {
  height: 10vh;
  display: flex;
  align-items: center;
  @media (max-width: $tablet_width) {
    height: 5vh;
  }
  .horizontal {
    width: 100%;
    height: 2px;
    //   margin: 0 20px;
    background: repeating-linear-gradient(
      to right,
      $dark 2px,
      $dark 15px,
      transparent 0px,
      transparent 35px
    );
    background-size: calc(100px / 4) 2px;
    opacity: 0.3;
  }
}
.vertical {
  width: 2px;
  height: 100%;
  margin: 0 20px;
  background: repeating-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.261) 3px,
    rgba(0, 0, 0, 0.261) 13px,
    transparent 0px,
    transparent 35px
  );
  background-size: 2px calc(100px / 4);
  opacity: 0.7;
}

.straight-line {
  border: 1px solid white;
  width: 1px;
}
