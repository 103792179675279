@import '../../../assets/styles/sizes';
@import '../../../assets/styles/colors';

.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    background-color: #005844;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 1rem 3.5rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    @media (max-width: $tablet_width) {
      padding: 1rem;
    }
  }
  .table {
    border: 1px solid #00000033;
    width: 100%;
    padding: 1rem 0rem;
    thead {
      th {
        // width: 100px !important;
        padding: 1.5rem 3.5rem;
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
      }
    }

    tbody {
      tr {
        td {
          padding: 0rem 3.5rem;

          font-weight: normal;
          @media (max-width: $tablet_width) {
            padding: 0rem 1rem;
          }
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin: 6px 0;
      }
    }
  }

  .responsiveTH {
    @media (max-width: $tablet_width) {
      display: none;
    }
  }
  .responsiveTR {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    @media (max-width: $tablet_width) {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
  .responsive-semester {
    display: flex;
    flex-direction: column;
  }
  .semester-title {
    @media (max-width: $tablet_width) {
      color: $primary_color;
    }
  }

  .hideOnTablet {
    @media (max-width: $tablet_width) {
      display: none;
    }
  }
  .showOnTablet {
    display: none;
    @media (max-width: $tablet_width) {
      display: block;
    }
  }
}

.fees-table {
  border: 1px solid #00000033;
  margin-top: 1rem;
  .table-header,
  .table-row {
    // border: 1px solid #00000033;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .table-header {
    .header-cell {
      border: 1px solid #005844;
      background-color: #005844;
      flex: 1;
      padding: 1rem 3rem;
      color: white;
      text-align: center;

      @media (max-width: $phone_width) {
        padding: 1rem 1rem;
      }
    }
  }
  .table-row-wrapper {
    padding: 2rem 0rem 0rem 0rem;
  }
  .table-row {
    border: none !important;
    .row-cell {
      flex: 1;
      padding: 0rem 3rem;
      text-align: center;
    }
  }
  .row-cell-container {
    .row-cell {
      padding: 0px 10px;
    }
  }
}
.program-table {
  border: 1px solid #00000033;
  .table-header,
  .table-row {
    border: 1px solid #00000033;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .table-header {
    .header-cell {
      background-color: #005844;
      flex: 1;
      padding: 1rem 3rem;
      color: white;
    }
  }
  // .table-row-wrapper {
  //   padding: 2rem 0rem 0rem 0rem;
  // }
  ul {
    padding: 0;
  }
  .table-row {
    border: none !important;
    display: flex;
    justify-content: flex-start;
    @media (max-width: $tablet_width) {
      flex-direction: column;
    }
    .row-cell {
      padding: 0rem 3rem;
      min-width: 475px;
      @media (max-width: $tablet_width) {
        min-width: auto;
      }
    }
  }
  .row-cell-container {
    .row-cell {
      padding: 0px 10px;
    }
  }
  .programTitle {
    color: $primary_color;
    text-transform: uppercase;
  }
}

.hideOnPhone {
  @media (max-width: $phone_width) {
    display: none;
  }
}

.showOnPhone {
  display: none;
  @media (max-width: $phone_width) {
    display: block;
  }
}

.alignRight {
  text-align: right !important;
}

.separatorWrapper {
  padding: 0rem 2rem;
}

.endLine {
  height: 50px;
}
.title {
  text-transform: capitalize;
}

.removePadding {
  padding: 0rem 2rem !important;
}
.alignCenter {
  text-align: center !important;
  background-color: red;
}
