@import '../../../assets/styles/sizes';

.card {
  box-sizing: border-box;
  display: flex;
  border-radius: 10px 10px 10px 10px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.158) 0% 0% no-repeat padding-box;
  height: 100%;
  border-radius: 10px;

  @media (max-width: $phone_width) {
    min-height: 200px;
  }
  // @media (max-width: $phone_width) {
  //   height: 140px;
  // }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    width: 100%;
    flex: 1;
    padding: 1vh 1.5vw !important;

    .content {
      display: flex;
      flex-direction: column;
      gap: 1vh;
    }
    .viewMore {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      // margin-top: 3vh !important;
    }
  }
  .imageWrapper {
    @media (-webkit-device-pixel-ratio: 1.75) {
      width: 45%;
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 45%;
    }
    @media (max-width: $tablet_width) {
      // width: 55%;
    }
    @media (max-width: $phone_width) {
      width: 50%;
      min-width: 50px;
    }
    @media (max-width: $phone_width) {
      display: none;
    }
    img {
      border-radius: 10px 0 0 10px;
      width: 300px;
      height: 100%;
      object-fit: cover;
      @media (-webkit-device-pixel-ratio: 1.75) {
        width: 100%;
      }
      @media (-webkit-device-pixel-ratio: 1.5) {
        width: 100%;
      }
      @media (max-width: $phone_width) {
        display: none;
      }
    }
  }
}
//for horizontal style card
.horizontal-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.158) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  border-radius: 10px;
  .content-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    flex: 1 1;
    @media (max-width: $tablet_width) {
      height: 100%;
    }
    @media (max-width: $phone_width) {
      padding: 0.7rem !important;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 1vh;
      // padding: 1rem;

      @media (max-width: $tablet_width) {
        max-width: none;
        width: 100%;
        gap: 1rem;
      }
    }
    .viewMore {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      cursor: pointer;
      // text-transform: uppercase;
    }
  }

  .imageWrapper {
    width: 100%;
    // height: 50%;
    background-position: cover;
    // @media (max-width: $tablet_width) {
    //   height: 30%;
    // }
    // @media (max-width: $phone_width) {
    //   height: 100px !important;
    // }
    img {
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;

      // height: 30vh;
    }
  }
}

.white-background {
  background: rgba(255, 255, 255, 0.11) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00584429;
}

.event-card {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .image-header {
    display: flex;
    height: 150px;

    img {
      width: 50%;
      object-fit: cover;
    }
  }

  .event-content {
    height: 200px;
  }
}

.hide {
  @media (max-width: $phone_width) {
    display: none;
  }
}
