@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.year-range-svg-container {
  .texts-container {
    display: flex;
    gap: 1rem;
    @media (-webkit-device-pixel-ratio: 1.25) {
      gap: 0.5rem;
    }
    @media (max-width: $tablet_width) {
      gap: 0.5rem;
    }
  }
}

.rtlClass {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.year-range-title {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  @media (-webkit-device-pixel-ratio: 1.25) {
    gap: 0.5rem;
  }
  @media (max-width: $tablet_width) {
    gap: 0.5rem;
  }
  @media (max-width: $phone_width) {
    gap: 0.2rem;
  }
}
.title {
  text-transform: uppercase;
}
.hasLine {
  border-bottom: 0.8rem solid $primary_color_dark;
  @media (-webkit-device-pixel-ratio: 1.5) {
    border-bottom: 0.4rem solid $primary_color_dark;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    border-bottom: 0.4rem solid $primary_color_dark;
    height: 60px;
  }
}

.year-range-svg {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 60px;
    height: 60px;
    @media (-webkit-device-pixel-ratio: 1.25) {
      width: 50px;
      height: 50px;
    }

    @media (max-width: $tablet_width) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: $phone_width) {
      width: 30px;
      height: 30px;
    }
  }
}

.year-range-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  border: 1px solid white;
  @media (-webkit-device-pixel-ratio: 1.5) {
    width: 52px;
    height: 52px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    width: 62px;
    height: 62px;
  }
  @media (max-width: 1300px) {
    width: 60px;
    height: 60px;
    justify-content: center;
  }
  @media (max-width: $tablet_width) {
    height: 70px;
    width: 70px;
  }
  @media (max-width: $phone_width) {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-top: 6px;
    span {
      display: none;
    }
  }
}

.svg-card {
  display: flex;
  gap: 1rem;

  @media (max-width: $tablet_width) {
    align-items: flex-start;
    gap: 0.5rem;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      font-size: smaller;
      color: white;
      font-size: 16px;
      font-weight: 100 !important;
      letter-spacing: 1px;

      @media (max-width: $tablet_width) {
        font-size: 15px;
      }
      @media (max-width: $phone_width) {
        font-size: 13px;
      }
    }
  }

  img {
    width: 50px;
    height: 50px;

    @media (max-width: 1300px) {
      width: 30px;
      height: 30px;
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 30px;
      height: 30px;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: $tablet_width) {
      width: 60px;
      height: 60px;
    }
    @media (max-width: $phone_width) {
      display: none;
    }
  }
}

.decorativeLine1 {
  height: 0.7rem;
  width: 200px;
  background: $primary_color_dark 0% 0% no-repeat padding-box;
}

.noGap {
  gap: 0rem !important;
}
