/* CheckBox.module.scss */

.checkbox-container {
  display: flex;
  align-items: center;
  transition: 1s all ease-in-out;

  .labels {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 0.5rem;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      input[type='checkbox'] {
        display: none;
      }

      .custom-checkbox {
        border-radius: 4px;
        width: 20px;
        height: 20px;
        border: 1px solid #000;
        // margin-right: 10px;
        transition: transform 0.5s ease-in-out;

        &:hover {
          border: 1px solid red;

          .image {
            transform: translateX(0px);
            opacity: 0.5;
            display: inline;
          }
        }

        &.show-image {
          background-color: white;
          border: 1px solid black;
          color: white;
        }

        .image {
          transform: translateX(5px);
          opacity: 0;
          // display: none;
          transition: transform 0.5s ease-in-out;
        }
      }
    }
  }
}
.checked {
  transition: transform 0.5s ease-in-out;
  transform: translate(4px, -4px);
}

.text {
  margin-top: -5px;
}
