@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.container {
  position: fixed;
  min-height: 40vh;
  top: 92px;
  left: 2vw;
  z-index: 2;
  transition: 1s ease-in-out;

  .green-media {
    filter: brightness(0) saturate(100%) invert(24%) sepia(49%) saturate(3990%)
      hue-rotate(149deg) brightness(101%) contrast(101%);
  }
  @media (max-width: $tablet_width) {
    top: 76px;
  }
  @media (max-width: $phone_width) {
    display: none;
  }

  img {
    @media (max-width: $phone_width) {
      display: none;
    }
  }

  .social-media-line {
    border-left: 1px solid $secondary_color_semi_transparent;
    height: 15vh;

    @media (max-width: $phone_width) {
      display: none;
    }
  }

  .green-socials {
    transition: 1s ease-in-out;
    border-left: 1px solid $primary_color;
  }

  .Social-Media {
    margin-left: -1.3194vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
    transition: 1s ease-in-out;
    .media {
      cursor: pointer;
    }
    img {
      width: 2.5vw;
      max-width: 50px;
      min-width: 30px;
    }
  }

  @media (max-width: $phone_width) {
    top: 3.5417vw;
  }
}

.container-with-margin-top {
  top: 9.7083vw;

  @media (-webkit-device-pixel-ratio: 1.25) {
    top: 6.9444vw;
  }
}
