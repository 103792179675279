.container {
  position: relative;

  overflow: hidden;
}

.backdropImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  object-position: center;
  background-attachment: fixed;
  transform: scale(1);
  transition: 1s ease-in-out;
}

.scale {
  transition: 1s ease-in-out;

  transform: scale(1.1);
}

.animated {
  animation: smoothZoom 40s infinite ease-in-out;
}

@keyframes smoothZoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
