@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';
.contactContainer {
  position: relative;
  // margin-top: 5rem;
  border-radius: 10px;
  .text {
    text-transform: uppercase;
  }
}
.form {
  padding: 3rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  @media (max-width: $phone_width) {
    padding: 2rem 1rem;
  }
}
.contact-form {
  position: relative;
  // min-height: 50vh;
  height: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  @media (max-width: $tablet_width) {
    flex-direction: column;
    padding: 2rem 2rem;
  }
  @media (max-width: $phone_width) {
    padding: 0rem 0rem;
  }

  .content-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    border-right: 2px solid rgba(255, 255, 255, 0.608);
    @media (max-width: $tablet_width) {
      width: 100%;
      border-right: 0px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.608);
      gap: 2rem;
      padding-bottom: 1rem;
    }
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    height: 50%;
    gap: 0.5rem;
  }
  .subscribe-container {
    width: 60%;
    display: flex;
    justify-content: center;
    @media (max-width: $tablet_width) {
      width: 100%;
      padding-top: 1rem;
      justify-content: flex-start;
    }
    .subscribe {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: $tablet_width) {
        width: 100%;
        gap: 1rem;
      }
    }
    .button {
      border-radius: 30px;
      background-color: $secondary_color;
      opacity: 0.6;
      &:hover {
        background-color: $primary_color;
        opacity: 1;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: $tablet_width) {
        width: 100%;
        gap: 2rem;
      }
    }
  }
}
