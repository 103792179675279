@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.tab-headers {
  display: flex;
  align-items: flex-end;
  height: 50px;

  @media (max-height: $device_with_less_height) and (min-width: 1280px) {
    height: 30px;
    min-height: 10px;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 6px;
    width: 200px;
    background: $secondary_color_semi_transparent 0% 0% no-repeat padding-box;
    border-radius: 1px solid;
    transition:
      background-color 0.3s ease,
      height 0.3s ease,
      border-radius 0.3s ease;
    height: 35px;
    span {
      cursor: pointer;
    }
    @media (max-width: $phone_width) {
      width: 100%;
    }
    &.active {
      height: 45px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background: #ffffffcc;
    }
  }
}

.tab-content {
  background: #ffffffcc 0% 0% no-repeat padding-box;
  max-height: 60vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $primary_color_light_transparent
    $primary_color_semi_transparent;
  opacity: 0.8;
  @media (max-width: $tablet_width) {
    width: 100%;
    max-height: 40vh;
  }
  @media (max-width: $phone_width) {
    width: 100%;
    max-height: 30vh;
  }

  img {
    width: 100%;
  }

  // Styles for Webkit browsers
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $secondary_color;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary_color_light_transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary_color;
  }
}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5rem 0%;
  @media (max-width: $phone_width) {
    padding: 3rem 0%;
  }
  img {
    width: 50%;
    height: 50%;
  }
}
