@import '../../../assets/styles/sizes';
.major-container {
  position: relative;
  width: 100%;
  height: 85vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: $phone_width) {
    background-attachment: local;
  }
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.321);
    z-index: 0;
    height: 100% !important;
  }
  .images {
    width: 100%;
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    @media (max-width: $tablet_width) {
      object-fit: cover;
    }
    // object-fit: cover;
  }
  @media (max-width: $tablet_width) {
    height: 60vh;
  }
  @media (max-width: $phone_width) {
    height: 100vh;
    background-position: 0% 10%;
  }

  .major-heading {
    width: 80%;
    height: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // background-color: red;

    @media (max-width: $tablet_width) {
      height: 60vh;
      justify-content: center;
    }
    @media (max-width: $phone_width) {
      justify-content: center;
    }
    .heading {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      // background-color: blue;
      gap: 2rem;
      @media (-webkit-device-pixel-ratio: 1.25) {
        gap: 2rem;
      }
      @media (max-width: $tablet_width) {
        width: 100%;
        justify-content: center;
        gap: 2rem;
      }
      @media (max-width: $phone_width) {
        justify-content: center;

        img {
          width: 40px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
      }
      .grid {
        width: 100%;
        margin-bottom: -3rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;
        @media (max-width: $phone_width) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}

.image {
  @media (-webkit-device-pixel-ratio: 1.75) {
    width: 30px;
  }
}
