@import '../../../assets/styles/colors';
@import '../../../assets/styles/sizes';

.card {
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  background: rgba(255, 255, 255, 0.11) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  .image {
    width: 100%;

    :first-child {
      border-radius: 10px 0px 0px 0px;
    }
    :nth-child(2) {
      border-radius: 0px 10px 0px 0px;
    }
    img {
      width: 50%;
      height: 30vh;
      max-height: 40vh;
      object-fit: cover;
      @media (max-width: $tablet_width) {
        max-height: 150px;
      }
      @media (max-width: 780px) {
        display: none;
      }
    }
  }
}
.titles {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
  width: 100%;
  @media (max-width: $phone_width) {
    margin: 0.5rem 0rem;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.3rem;
  }
  .text {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0rem 2vw;
    display: flex;
    @media (max-width: $phone_width) {
      padding: 0rem 1rem;
    }
    // justify-content: center;
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      border-right: 1px solid $secondary_color_semi_transparent;
      border-left: 1px solid $secondary_color_semi_transparent;
      @media (max-width: $phone_width) {
        justify-content: flex-start;
      }
    }
    &:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      @media (max-width: $phone_width) {
        justify-content: flex-start;
      }
    }

    img {
      width: 29px !important;
      height: 29px;
      @media (-webkit-device-pixel-ratio: 1.75) {
        width: 20px;
        height: 20px;
      }
      @media (-webkit-device-pixel-ratio: 1.5) {
        width: 20px;
        height: 20px;
      }
      @media (-webkit-device-pixel-ratio: 1.25) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.content-container {
  display: flex;
  flex-direction: column;
}
.content {
  box-sizing: border-box;
  padding: 1vh 2vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  @media (max-width: $phone_width) {
    gap: 0.3rem;
    padding: 0.5rem 1rem;
  }
  .location {
    gap: 1rem;
    @media (-webkit-device-pixel-ratio: 1.75) {
      gap: 0rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      gap: 0rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      gap: 0rem;
    }

    img {
      width: 30px;
      height: 40px;
      @media (-webkit-device-pixel-ratio: 1.75) {
        width: 20px;
        height: 30px;
      }
      @media (-webkit-device-pixel-ratio: 1.5) {
        width: 20px;
        height: 30px;
      }
      @media (-webkit-device-pixel-ratio: 1.25) {
        width: 20px;
        height: 30px;
      }
    }
  }
  img {
    width: 29px;
    height: 29px;
  }
  .description {
    width: 100%;
    max-width: 900px;
  }
}

.view-more {
  display: flex;
  justify-content: flex-end;
  // padding: 1vh 1vh;
  // margin-top: 2vh;
  img {
    width: 1vw;
    min-width: 15px;
    min-height: 15px;
    @media (max-width: 780px) {
      display: block;
    }
  }
}
