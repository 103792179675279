@import '../../../../../assets/styles/sizes';
.Step-three {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  .radioLabelClass {
    color: rgba(0, 0, 0, 0.649);
    text-transform: capitalize;
    width: 200px;
  }
  .input-row {
    display: flex;
    gap: 1rem;
    @media (max-width: $tablet_width) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
