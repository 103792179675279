@import '../../../../../../assets/styles/sizes';

.cards-section {
  margin-top: 2em;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../stepperSection/backgroundImage.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: $tablet_width) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.cards-section2 {
  width: 100vw;
  height: 100dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../stepperSection/backgroundImage.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: $tablet_width) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.card {
  // background-color: white;
  border-radius: 30px;
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  gap: 1rem;
  border: 1px solid white;
  @media (max-width: $tablet_width) {
    padding: 0.5rem 1rem;
  }
  .image {
    height: 4rem;
    @media (max-width: $phone_width) {
      height: 2rem;
    }
  }
}
.title {
  @media (max-width: $tablet_width) {
    margin-top: 17vh;
    padding: 1rem;
  }
}
.cards-grid {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  //   margin: auto;
  @media (max-width: 1500px) {
    width: 70%;
    padding: 1rem;
    // height: 70%;
    gap: 0.5rem;
    margin: 0;
  }
  @media (max-width: $tablet_width) {
    width: 100%;
    padding: 1rem;
    height: 100%;
    gap: 0.5rem;
    margin: 0;
  }
  @media (max-width: $tablet_width) {
    grid-template-columns: repeat(1, 1fr);
    height: 80%;
  }
  // @media (max-width: $tablet_width) {
  //   margin-top: 6rem;
  // }
}

.letter {
  position: relative;
  .text {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.footer {
  // display: flex;
  // flex-direction: column;
  gap: 0.2rem;
}
.seeMore {
  text-decoration: underline;
}
