@import '../../../../../../assets/styles/sizes';

.slide-three {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    @media (max-width: $tablet_width) {
      width: 80%;
      margin: auto;
      text-align: center;
    }
  }
}
