@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/sizes';

.container-mobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60px;
  bottom: 0px;
  z-index: 4;
  opacity: 1;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  transition: height 0.3s ease-in-out;
  transition: 0.1s all ease-in-out;
  background: #0000003f 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(24px);
  @media (max-width: $tablet_width) {
    min-height: 70px;
    span {
      font-size: 20px;
    }
  }
  @media (max-width: $phone_width) {
    min-height: 50px;
    span {
      font-size: 13px;
    }
  }
  @media (min-width: $tablet_width) {
    display: none;
  }
  .header {
    transition: 1s ease-in-out;
    min-height: 5vh;
  }
  .header-button {
    display: flex;
    gap: 0.5rem;
    padding-left: 20px;
    margin-top: 6px;
    transition: 0.3s ease-in-out;

    img {
      height: 30px;
      margin-top: -1rem;
      transition: 0.3s ease-in-out;

      @media (max-width: $tablet_width) {
        height: 50px;
        margin-top: -0.9rem;
      }
      @media (max-width: $phone_width) {
        height: 30px;

        margin-top: -0.4rem;
      }
    }
  }
  .navigations-container {
    display: none;
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    width: 80% !important;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid $secondary_color_semi_transparent;
    padding: 18px;
  }
}

.expanded {
  min-height: 20vh;
  @media (max-width: $tablet_width) {
    min-height: 40vh;
  }
  @media (max-width: $phone_width) {
    min-height: 20vh !important;
    padding-bottom: 50px;
  }

  .navigations-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .navigations {
      margin-top: 4rem;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: space-around;
      justify-content: center;
      align-items: center;
      @media (max-width: $tablet_width) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 2rem;
      }
      @media (max-width: $phone_width) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        margin-top: 2rem;
        grid-row-gap: 2rem !important;
      }
      div:last-child {
        border-right: none;
        a {
          border-right: none;
        }
      }
    }
  }
}
.visible {
  position: absolute;
}

.hideNav {
  display: none;
}

.shownav {
  display: block;
}

.marginRight {
  margin-right: 1.5rem;
}

.rotate {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
  margin-top: 0.7rem !important;
}
