@import '../../../../../../assets//styles//colors';
@import '../../../../../../assets//styles/sizes';

.slide-one {
  width: 100%;
  height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  position: relative;
  @media (max-width: $phone_width) {
    background-position: 50% 100%;
  }
  &.testimonial {
    .students-info {
      .left-quotes,
      .right-quotes {
        filter: $primary_color_svg;
      }

      .right-border,
      .left-border,
      &::before,
      &::after {
        background: $primary_color;
      }
    }
  }
  @media (max-width: $tablet_width) {
    flex-direction: row;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80vw;
    // margin-left: 160px;
    margin: auto;
    position: relative;
    @media (max-width: $tablet_width) {
      flex-direction: column;
      width: 80vw;
      margin: auto;
    }

    @media (max-width: $phone_width) {
      width: 80%;
    }
  }
  .students-info {
    position: relative;
    margin-top: 7rem;
    min-width: 50vw;
    // max-width: 70vw;
    z-index: 2;
    // height: 150px;
    padding: 3rem 3rem 5rem 3rem;
    @media (max-width: $tablet_width) {
      padding: 2rem;
      padding-bottom: 5rem;
    }
    @media (max-width: $phone_width) {
      margin-top: 0vh;
    }
    .left-quotes {
      position: absolute;
      top: 0;
      left: -20px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
        hue-rotate(146deg) brightness(106%) contrast(100%);
      @media (max-width: $tablet_width) {
        left: -20px;
        top: 30px;
      }
      @media (max-width: $phone_width) {
        width: 20px;
      }
    }
    .right-quotes {
      position: absolute;
      bottom: 0;
      right: -20px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%)
        hue-rotate(146deg) brightness(106%) contrast(100%);
      @media (max-width: $tablet_width) {
        right: -0px;
        bottom: 0;
      }
      @media (max-width: $phone_width) {
        width: 20px;
        right: 3px;
      }
    }
    // Right Border
    .right-border {
      content: '';
      position: absolute;
      width: 2px;
      background: white;
      top: 0;
      right: 0;
      height: 80%;
    }

    // Left Border
    .left-border {
      content: '';
      position: absolute;
      width: 2px;
      background: white;
      bottom: 0%;
      left: 0;
      height: 60%;
    }
    // Top Border
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      background: white;
      top: 0;
      right: 0;
      left: 40%;
    }

    // Bottom Border
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      background: white;
      bottom: 0;
      left: 0;
      right: 40%;
      @media (max-width: $phone_width) {
        display: none;
      }
    }
    .description {
      margin-top: 1rem;
    }
  }
  .title-container {
    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: -20px;
    right: 70px;
    width: 400px;
    gap: 10px;
    @media (max-width: $phone_width) {
      margin-left: 10px;
      width: auto;
    }
  }
}

.image {
  position: absolute;
  min-width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100%;
  z-index: -1;
  img {
    // width: 100%;
    height: 100%;
  }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.377);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
