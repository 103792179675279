@import '../../../../../../../assets/styles/sizes';

.letter-container {
  width: 100%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

.grid-item {
  flex: 1 1 calc(25% - 1rem);
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .grid-item {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .grid-item {
    flex: 1 1 calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
}
.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0rem;
  text-transform: uppercase;

  @media (max-width: $tablet_width) {
    display: none !important;
  }
  .text {
    cursor: pointer;
  }
}
.view-all-button {
  display: none;
  margin-top: 1rem;
  padding: 1rem 0rem;
  text-transform: uppercase;
  @media (max-width: $tablet_width) {
    display: flex;
  }
}

.card {
  width: 100%;
  @media (max-width: $tablet_width) {
    height: 100%;
  }
}

// .card-content {
//   // padding: 2rem !important;
// }

.imageClass {
  width: min-content !important;
  @media (max-width: $tablet_width) {
    height: 20vh !important;
  }
}

.image-class-name {
  height: 40vh;
  width: min-content;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: $phone_width) {
    height: 17vh !important;
  }
}
.horizontal-card-class-name {
  height: 100%;
}

.content-class-name {
  min-height: 100px !important;
}
