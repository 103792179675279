@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/sizes';

//the menu main menu
.menu {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  border-right: 1px solid transparent;
  padding-right: 20px;

  &:hover {
    border-right: 1px solid $secondary_color_semi_transparent;
  }
  .link {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      color: $primary_color;
      img {
        filter: brightness(0) saturate(100%) invert(12%) sepia(92%)
          saturate(2071%) hue-rotate(126deg) brightness(93%) contrast(88%);
        // transform: translateX(5rem);
        transition: 0.3s all ease-in-out;
        @media (max-width: $tablet_width) {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.rtl-menu {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding-right: 20px;
  border-right: none !important;
  border-left: 1px solid transparent;

  &:hover {
    border-left: 1px solid $secondary_color_semi_transparent;
    border-right: none !important;
  }
  .link {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    img {
      transform: rotate(178deg);
    }
    &:hover {
      color: $primary_color;
      img {
        filter: brightness(0) saturate(100%) invert(12%) sepia(92%)
          saturate(2071%) hue-rotate(126deg) brightness(93%) contrast(88%);
        transform: translateX(-5rem) rotate(178deg);

        transition: 0.3s all ease-in-out;
        @media (max-width: $tablet_width) {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.active-link {
  color: $primary_color;

  img {
    filter: brightness(0) saturate(100%) invert(12%) sepia(92%) saturate(2071%)
      hue-rotate(126deg) brightness(93%) contrast(88%);
    // transform: translateX(5rem);

    transition: 0.3s all ease-in-out;
    @media (max-width: $tablet_width) {
      transform: rotate(90deg);
    }
  }
}
// the childrens
.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;

  .sub-menu {
    display: flex;
    flex-direction: column;
    gap: 3vh;

    .child-link {
      cursor: pointer;
      // margin-bottom: 2rem;
      @media (max-width: $tablet_width) {
        margin-bottom: 1rem;
        font-size: 18px;
      }
      &:hover {
        color: $primary_color;
      }
    }
  }
}

.inner-child-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  @media (max-width: $tablet_width) {
    gap: 1px;
  }
}

.inner-subs-container {
  // background-color: red;
}

.flexed-row {
  flex-direction: row !important;
  gap: 4vh !important;
  @media (max-width: $tablet_width) {
    flex-direction: column !important;
    gap: 10px !important;
    max-height: 65vh;
    overflow: auto;
  }
}

.borderRight {
  border-right: 1px solid $secondary_color_semi_transparent;
}
