@import '../../../../../assets/styles//colors';

.Step-five {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .link {
      color: $primary_color;
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
}
