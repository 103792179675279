@import '../../../../assets/styles/sizes';
.heading {
  // margin-top: 4rem;
  @media (max-width: $tablet_width) {
    // margin-top: 1rem;
  }
}

.admissions-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
