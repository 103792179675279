@import '../../../assets/styles/sizes';

.read-more-modal {
  //   min-width: 1000px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  .close {
    position: absolute;
    top: 10px;
    right: 1rem;
    z-index: 1001;
    cursor: pointer;
    @media (max-width: $tablet_width) {
      width: 40px;
    }
    @media (max-width: $phone_width) {
      width: 40px;
    }
  }
  .image {
    // border-radius: 30px;
    width: 100%;
    .img {
      // border-radius: 20px;

      // border-top-left-radius: 10px;
      // border-top-right-radius: 10px;
      // border-radius: 50px;
      width: 100%;
      // height: 40vh;

      object-fit: cover;
      // max-height: 500px;
    }
  }
  .content {
    min-height: 10px;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    @media (max-width: $phone_width) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .titles {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    text-transform: uppercase;
    margin: 0.5rem 0;
    @media (max-width: $phone_width) {
      margin: 0.5rem 0rem;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.3rem;
    }
    .text {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      display: flex;
      @media (max-width: $phone_width) {
        padding: 0rem 1rem;
      }
      &:nth-child(2) {
        display: flex;
        justify-content: center;
        border-left: 1px solid black;
        @media (max-width: $tablet_width) {
          border-left: 0px solid black;
        }
        @media (max-width: $phone_width) {
          justify-content: flex-start;
        }
      }
      &:nth-child(3) {
        display: flex;
        justify-content: center;
        border-left: 1px solid black;
        font-size: 14px;
        @media (max-width: $tablet_width) {
          border-left: 0px solid black;
        }
        @media (max-width: $phone_width) {
          justify-content: flex-start;
        }
      }
      &:nth-child(4) {
        margin-top: 2rem;
        width: 100% !important;
      }
      img {
        width: 25px !important;
        height: 25px;
      }
    }
  }
}

.read-more-body {
  min-width: 70vw;
  padding: 0 !important;

  @media (max-width: $tablet_width) {
    min-width: 80vw !important;
  }
  @media (max-width: $phone_width) {
    min-width: 80vw !important;
  }
  .nextArrow {
    left: 30px !important;
  }
  .prevArrow {
    right: 30px !important;
  }
}

.carouselContainer {
  min-height: 20vh;
}
.dialogClassName {
  padding: 0 !important;
  max-width: 50vw;
  @media (max-width: $tablet_width) {
    max-width: 90%;
    margin: auto;
  }
  @media (max-width: $phone_width) {
    max-width: 90%;
    margin: auto;
  }
}
.modalImage {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 4px 4px 0 0;

  @media (max-width: $phone_width) {
    height: 50vh;
  }
}

.modalBody {
  padding: 0;

  // min-width: 900px;
}

.desc {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  max-height: 300px;
  overflow-y: scroll;
}
