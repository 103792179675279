@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .link {
    width: 100%;
    padding: 0.4rem;
    color: $secondary_color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.5s ease-in-out;
    user-select: none;
    text-decoration: none;
    max-height: 30px;
    &.with-right-border {
      border-right: 1px solid $secondary_color;
    }
  }

  .bordered {
    border: 1px solid $secondary_color;
    border-radius: 50px;
  }

  .text {
    position: relative;
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $primary_color;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 100%;
      bottom: -5px;
      height: 1px;
      background-color: $primary_color;
      transition:
        right 0.4s cubic-bezier(0.86, 0, 0.07, 1),
        background-color 0.5s ease-in-out;
    }
  }
}

.active {
  .text {
    color: $primary_color;

    &::after {
      right: 0;
    }
  }
}

.with-right-odd {
  border-left: 1px solid $secondary_color;
}
.with-left-border {
  border-right: none !important;
  border-left: 1px solid $secondary_color;
}
.with-right-border {
  border-right: 1px solid $secondary_color;
}
