@import '../../../../assets/styles/sizes';

.yearWrapper {
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  opacity: 1;
  transition:
    opacity 1s ease-in-out,
    transform 1s ease-in-out;
}

.yearWrapper-visible {
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.year_menu {
  position: absolute;
  display: flex;
  right: -200px;
  top: 0%;
  padding-left: 24px;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.49) 11%,
    rgba(0, 0, 0, 0) 69.96%
  );

  flex-direction: column;
  z-index: 1;
  height: 100%;
  position: fixed;
  justify-content: center;
  transition: 1s ease-in-out;
  opacity: 0;
  @media (max-width: $tablet_width) {
    width: 175px;
  }
  @media (max-width: $phone_width) {
    right: 0;
    top: -200px;
    height: auto;
    width: 100%;
    flex-direction: row;
  }
}

.year_menu-visible {
  opacity: 1;
  right: 0 !important;
  @media (max-width: $phone_width) {
    top: 0 !important;
  }
}

.year_menu_top {
  position: fixed;
  display: none;
  top: -200px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.49) 11%,
    rgba(0, 0, 0, 0) 69.96%
  );

  flex-direction: row;
  z-index: 1;
  justify-content: center;
  transition: 1s ease-in-out;
  opacity: 0;
  align-items: flex-start;
  @media (max-width: 1200px) {
    display: flex;
    top: 0;
    margin: auto;
  }
}

.year_menu-visible-top {
  opacity: 1;
  top: 0 !important;
  padding: 0 300px;
  @media (max-width: $tablet_width) {
    padding: 0 200px !important;
  }
  @media (max-width: $phone_width) {
    padding: 0 50px !important;
  }
}

.history-wrapper {
  background-color: #0000003a;
}
