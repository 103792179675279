@import '../../../../../../assets/styles/sizes';

.slide-two {
  position: relative;
  width: 100%;
  min-height: 100dvh;

  .slide {
    width: 90%;
    min-height: 100dvh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4vh;
    // margin-top: -20px;
    @media (max-width: $phone_width) {
      gap: 5px;
      // min-height: 85vh;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .content-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(190px, 1fr));
    // grid-template-rows: repeat(1, 1fr);
    gap: 1vw;
    width: 90%;
    @media (max-width: $tablet_width) {
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
    }

    @media (max-width: $phone_width) {
      width: 90%;
    }
  }

  .text {
    @media (max-width: $tablet_width) {
      display: none;
    }
    @media (max-height: $device_with_less_height) {
      display: none;
    }
  }
}

.loader {
  width: 100%;
}
