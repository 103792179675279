.video {
  position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
}

.videoAlbum {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.3;
}
.videoControlsHidden {
  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  /* For Firefox */
  &::-moz-media-controls {
    display: none !important;
  }

  &::-moz-media-controls-start-playback-button {
    display: none !important;
  }

  /* For Microsoft Edge */
  &::-ms-media-controls {
    display: none !important;
  }

  &::-ms-media-controls-start-playback-button {
    display: none !important;
  }
  video::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  video:state(webkit-media-controls-play-button) {
    display: none !important;
    // -webkit-appearance: none;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
}
