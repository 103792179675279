@import '../../../../../../assets/styles/sizes';

.section {
  height: 100dvh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-container {
  width: 77vw;
  min-height: 40vh;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  // grid-template-rows: repeat(1, 1fr);
  gap: 1rem;
  text-align: center;
  @media (max-width: 1661px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media (max-width: $phone_width) {
    width: 90%;
  }
  .card {
    background-color: white;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00584429;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    img {
      margin: auto;
      width: clamp(100px, 10vw, 200px);
    }
  }
}

.text-container {
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.countryName {
  text-align: center;
}
