.media-gallery {
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.ss {
  background-color: red;
  position: absolute;
  top: 500px;
  right: 100px;
  cursor: pointer;
}
.slide {
  width: 100%;
  height: 100vh;
  display: flex;
}

.empty {
  background-color: red;
  height: 200px;
}
