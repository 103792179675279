// original font sizes
$header: 80px;
$subheader: 30px;
$title: 20px;
$subtitle: 18px;
$link: 16px;
$paragraph: 13px;

@font-face {
  font-family: '29LT Bukra Bold';
  src: url('../fonts/TTF/29ltbukrabold.ttf') format('truetype');
}

@font-face {
  font-family: '29LT Bukra Bold Italic';
  src: url('../fonts/TTF/29ltbukrabolditalic.ttf') format('truetype');
}

@font-face {
  font-family: '29LT Bukra Light';
  src: url('../fonts/TTF/29ltbukralight.ttf') format('truetype');
}

@font-face {
  font-family: '29LT Bukra Regular';
  src: url('../fonts/TTF/29ltbukraregular.ttf') format('truetype');
}

@font-face {
  font-family: '29LT dubai bold';
  src: url('../fonts/arabic//DubaiBold.ttf') format('truetype');
}

$bukra_light: '29LT Bukra Light';
$bukra_bold: '29LT Bukra Bold';
$bukra_bold: '29LT Bukra Bold';
$bukra_regular: '29LT Bukra Regular';
// Define the font styles
$fontStyles: (
  // montserrat-extrabold-13px: (
  //   font-family: 'Montserrat',
  //   font-size: 13px,
  //   color: $secondary_color,
  // ),
  bukra-regular-14px:
    (
      font-family: $bukra_regular,
      font-size: 14px,
      color: $secondary_color,
    ),
  bukra-bold-14px-black: (
    font-family: $bukra_bold,
    font-size: 14px,
    color: $black,
  ),
  bold-regular-15px: (
    font-family: $bukra_regular,
    font-size: 15px,
    color: $secondary_color,
  ),
  bold-italic-15px: (
    font-family: $bukra_bold,
    font-size: 15px,
    color: $secondary_color,
  ),
  bold-italic-16px: (
    font-family: $bukra_bold,
    font-size: 16px,
    color: $secondary_color,
  ),
  bold-italic-16px-black: (
    font-family: $bukra_bold,
    font-size: 16px,
    color: $black,
  ),
  regular-16px: (
    font-family: $bukra_regular,
    font-size: 16px,
    color: $secondary_color,
  ),
  bold-regular-16px: (
    font-family: $bukra_regular,
    font-size: 16px,
    color: $secondary_color,
  ),
  bold-regular-16px-black: (
    font-family: $bukra_regular,
    font-size: 16px,
    color: $black,
  ),
  bold-regular-16px-dark: (
    font-family: $bukra_regular,
    font-size: 16px,
    color: $dark,
  ),
  bold-italic-16px-green: (
    font-family: $bukra_bold,
    font-size: 16px,
    color: $primary_color,
  ),
  bold-italic-18px: (
    font-family: $bukra_bold,
    font-size: 18px,
    color: $secondary_color,
  ),
  bold-italic-18px-black: (
    font-family: $bukra_bold,
    font-size: 18px,
    color: $black,
  ),
  bukra-regular-18px: (
    font-family: $bukra_regular,
    font-size: 18px,
    color: $secondary_color,
  ),
  bukra-regular-18px-green: (
    font-family: $bukra_regular,
    font-size: 18px,
    color: $primary_color,
  ),
  bukra-regular-18px-black: (
    font-family: $bukra_regular,
    font-size: 18px,
    color: $black,
  ),
  bukra-bold-18px: (
    font-family: $bukra_bold,
    font-size: 18px,
    color: $secondary_color,
  ),
  bold-italic-20px-black: (
    font-family: $bukra_bold,
    font-size: 20px,
    color: #000000,
  ),
  bold-italic-20px: (
    font-family: $bukra_bold,
    font-size: 20px,
    color: $secondary_color,
  ),
  bukra-regular-20px: (
    font-family: $bukra_regular,
    font-size: 20px,
    color: $secondary_color,
  ),
  bukra-regular-20px-black: (
    font-family: $bukra_regular,
    font-size: 20px,
    color: $black,
  ),
  bukra-bold-20px: (
    font-family: $bukra_bold,
    font-size: 20px,
    color: $secondary_color,
  ),
  bukra-bold-20px-green: (
    font-family: $bukra_bold,
    font-size: 20px,
    color: $primary_color,
  ),
  bukra-bold-20px-black: (
    font-family: $bukra_bold,
    font-size: 20px,
    color: $black,
  ),
  bold-italic-22px: (
    font-family: $bukra_bold,
    font-size: 22px,
    color: $secondary_color,
  ),
  bold-regular-22px: (
    font-family: $bukra_bold,
    font-size: 22px,
    color: $secondary_color,
  ),
  bold-regular-25px: (
    font-family: $bukra_regular,
    font-size: 25px,
    color: $secondary_color,
  ),
  bukra-bold-italic-25px-black: (
    font-family: $bukra_bold,
    font-size: 25px,
    color: $black,
  ),
  bukra-bold-25px-black: (
    font-family: $bukra_bold,
    font-size: 25px,
    color: $black,
  ),
  bukra-bold-italic-25px: (
    font-family: $bukra_bold,
    font-size: 25px,
    color: $secondary_color,
  ),
  bukra-bold-italic-25px-green: (
    font-family: $bukra_bold,
    font-size: 25px,
    color: $primary_color,
  ),
  bold-italic-30px-green: (
    font-family: $bukra_bold,
    font-size: 30px,
    color: $primary_color,
  ),
  bold-italic-30px: (
    font-family: $bukra_bold,
    font-size: 30px,
    color: $secondary_color,
  ),
  bold-italic-30px-black: (
    font-family: $bukra_bold,
    font-size: 30px,
    color: $black,
  ),

  bold-italic-33px: (
    font-family: $bukra_bold,
    font-size: 33px,
    color: $secondary_color,
  ),
  bold-italic-40px-black: (
    font-family: $bukra_bold,
    font-size: 40px,
    color: #000000,
  ),
  bold-italic-40px: (
    font-family: $bukra_bold,
    font-size: 40px,
    color: $secondary_color,
  ),
  bold-regular-40px: (
    font-family: $bukra_regular,
    font-size: 40px,
    color: $secondary_color,
  ),
  bold-italic-50px: (
    font-family: $bukra_bold,
    font-size: 50px,
    color: $secondary_color,
  ),
  bold-italic-60px: (
    font-family: $bukra_bold,
    font-size: 60px,
    color: $secondary_color,
  ),
  bold-italic-70px: (
    font-family: $bukra_bold,
    font-size: 70px,
    color: $secondary_color,
  ),
  bold-italic-80px: (
    font-family: $bukra_bold,
    font-size: 80px,
    color: $secondary_color,
  ),
  bold-italic-100px: (
    font-family: $bukra_bold,
    font-size: 100px,
    color: $secondary_color,
  ),
  bold-italic-180px: (
    font-family: $bukra_bold,
    font-size: 180px,
    color: $secondary_color,
  ),
  bold-80px: (
    font-family: $bukra_bold,
    font-size: 120px,
    color: $secondary_color,
  )
);
