@import '../../assets/styles/colors';
@import '../../assets/styles/sizes';

.register-container {
  background: transparent url('../../assets/images/dummyImage.png') 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1200px) {
    justify-content: center;
  }
  @media screen and (max-width: $phone_width) {
    background: white;
  }
  .form-container {
    margin: 5rem 2rem 5rem 0rem;
    width: 70%;
    min-width: 1000px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    display: flex;
    padding: 2rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.18531162464985995) 0%,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.4234068627450981) 0%,
      rgba(255, 255, 255, 1) 48%
    );
    @media (-webkit-device-pixel-ratio: 1.25) {
      margin: 3rem 2rem 3rem 0rem;
      padding: 2rem;
    }
    box-shadow: 0px 3px 6px #00000029;
    @media (max-width: 1010px) {
      width: 100%;
      min-width: 0px;
      flex-direction: column;
    }

    @media (max-width: $tablet_width) {
      margin: 4rem;
    }
    @media (max-width: $phone_width) {
      height: 100vh;
      margin: 1rem;
      padding: 1rem;
    }
    .left-side {
      width: 45%;
      padding-right: 4rem;
      @media (max-width: 1010px) {
        width: 100%;
      }
      @media (max-width: $tablet_width) {
        padding-right: 0rem;
      }
      img {
        filter: brightness(0) saturate(100%) invert(30%) sepia(78%)
          saturate(7074%) hue-rotate(157deg) brightness(98%) contrast(101%);
        width: 350px;
        @media (max-width: $phone_width) {
          width: 230px;
        }
      }
      .heading {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .text {
          color: #1b224c;
        }
        .text-ar {
          color: #1b224c;
          text-align: end;
        }
      }
    }
    .gender {
      display: flex;
      gap: 5em;
      // justify-content: space-between;
      align-items: center;
    }
    .register-form {
      width: 60%;
      padding: 2rem 4rem 0rem 4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      @media (max-width: 1010px) {
        width: 100%;
        padding: 0rem;
        margin-top: 30px;
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
      }
      .account-already {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        display: flex;
        gap: 20px;
        .texts {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }
}
