@import '../../../assets/styles/sizes';

.image-with-titles {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  // margin-top: 2rem;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 2rem;
    @media (max-width: $tablet_width) {
      padding: 2rem 1rem;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
    @media (max-width: $tablet_width) {
      width: 100%;
    }
  }
  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 20px;
  }
  .button {
    background-color: white;
    padding: 0.8rem 3rem;
    text-transform: uppercase;
    @media (max-width: $phone_width) {
      width: 100%;
    }
  }
}
