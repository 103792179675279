@import '../../../assets/styles/sizes';

.registration-app {
  .header {
    width: 100%;
    min-height: 50vh;
    background-image: url('../../../assets/images/campusLandscape.png');
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: $phone_width) {
      align-items: flex-start;
    }
  }
  .submit-button {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    @media (max-width: $phone_width) {
      flex-direction: column-reverse;
      gap: 0.5rem;
    }
  }
  .containerClass {
    @media (max-width: $tablet_width) {
      padding: 3rem;
    }
    @media (max-width: $phone_width) {
      padding: 0.5rem;
    }
  }
}
.submit-button-flex {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  @media (max-width: $phone_width) {
    flex-direction: column-reverse;
  }
}
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem 0rem 6rem 8rem;
  gap: 1rem;

  @media (max-width: $phone_width) {
    margin: 5rem 3rem;
  }
}
.titleRtl {
  margin: 0rem 3rem 6rem 0rem;
  @media (max-width: $phone_width) {
    align-self: flex-end;
  }
}
