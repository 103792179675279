@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/sizes';

.admissions-card {
  position: relative;
  display: flex;
  justify-content: flex-end;
  background: #00000099 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 2rem;

  @media (max-height: $device_with_less_height) {
    padding: 2vh 2vw;
  }

  @media (max-width: $tablet_width) {
    min-height: 0px !important;
    padding: 2vh 2vw;
  }

  @media (max-width: $phone_width) {
    min-height: 10px !important;
    padding: 10px;
    min-width: 60px;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20vh;
    padding: 0vh 2vw;

    @media (max-height: $device_with_less_height) {
      padding: 3vh 2vw;
      // min-height: 120px;
    }
    @media (max-width: $tablet_width) {
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      // gap: 0.5rem;
      margin-bottom: 0px;
      padding: 0rem 0rem;
      min-height: 10px;
    }
  }
  .card-subtitles {
    text-align: center;
    // padding: 0.2rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 2rem;
    align-items: right;
    // @media (max-width: $tablet_width) {
    //   display: none;
    // }
    @media (max-height: $device_with_less_height) {
      margin-top: 10px;
    }
  }
  .card-footer {
    // position: absolute;
    width: 100%;
    // height: 110px;
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (max-width: $tablet_width) {
      position: relative;
    }
    .footer {
      position: relative;
      // width: 85%;
      height: 100%;
      border-top-width: 3px;
      margin: auto;
      display: flex;
      padding: 30px 0px;
      background-size: calc(100% / 1) 2px;
      @media (max-height: $device_with_less_height) {
        padding: 10px 0px;
      }
      @media (max-width: $tablet_width) {
        width: 99%;
        padding: 10px 0px;
        flex-direction: column;
      }
      .date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 30px;
        gap: 10px;

        @media (max-width: $tablet_width) {
          padding-top: 5px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .date-texts {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (max-width: $tablet_width) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            margin-top: 5px;
          }
        }
      }
      .dashes-separator {
        position: absolute;
        height: 2px;
        width: 100%;
        margin-left: 10px;
        background-image: repeating-linear-gradient(
          to right,
          $disabled_color_semi_transparent,
          $disabled_color_semi_transparent 12.5%,
          transparent 0%,
          transparent 25%
        );

        background-size: calc(100% / 7) 2px;
      }
    }
  }
  .link {
    text-align: center;
    @media (max-width: $tablet_width) {
      text-align: right;
    }
    // &:hover {
    //   color: $primary_color;
    //   cursor: pointer;
    // }
  }
}
.en {
  @media (max-width: $tablet_width) {
    text-align: left !important;
  }
}
.card-subtitles-mobile {
  display: flex;
  flex-direction: column;
  display: none;
  @media (max-width: $tablet_width) {
    display: flex;
  }
}
